import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import upload from "../../../assets/icon/upload.svg";
import mp3 from "../../../assets/icon/mp3.svg";
import x from "../../../assets/icon/x.svg";

import cewe from "../../../assets/audio/cewe.wav"
import cowo from "../../../assets/audio/cowo.wav"
import example from "../../../assets/audio/exampleCloning.wav"

import Dropzone from 'react-dropzone'
import { Link } from "react-router-dom";
import { useState } from "react";
 
function VoiceCloning(params) {
    const [fileContext, setFileContext] = useState([])
    const [fileTarget, setFileTarget] = useState([])
    const [exampleContext, setExampleContext] = useState(false)
    const [exampleTarget, setExampleTarget] = useState(false)
    const [status, setStatus] = useState("");
    const [answer, setAnswer] = useState("");
    const [spamClick, setSpamClick] = useState(false);
    const [errorContext, setErrorContext] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);

    function formatBytes(bytes) {
        if (bytes < 1024) return bytes + ' Bytes';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes < 1024 * 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        else return (bytes / (1024 * 1024 * 1024)).toFixed(6) + ' GB';
    }

    const submitCloning = async () => {   
        setStatus("")

        if(fileContext.length === 0 || fileTarget.length === 0){

            if(fileContext.length === 0) {
                // setStatus("Please input your audio Context :))")

                const link = document.createElement("a");
                link.href = "#errorContext";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorContext(true);
                setTimeout(() => {
                    setErrorContext(false)
                }, 4000);
            }
            if(fileTarget.length === 0) {
                // setStatus("Please input your audio Target :))")

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }

        } else if (exampleContext && exampleTarget) {
            setAnswer(example);
            setStatus("Done")
        } else {
            setStatus("Loading")
            setSpamClick(true)
            const formdata = new FormData();

            if(exampleContext){
                const responsecewe = await fetch(cewe);
                const blobcewe = await responsecewe.blob();
                const filecewe = new File([blobcewe], "cewe.wav", { type: "audio/wav" })
                formdata.append("voiceContext", filecewe);
            }else{
                formdata.append("voiceContext", fileContext[0]);
            }

            if(exampleTarget){
                const responsecowo = await fetch(cowo);
                const blobcowo = await responsecowo.blob();
                const filecowo = new File([blobcowo], "cowo.wav", { type: "audio/wav" })
                formdata.append("voiceTarget", filecowo)
            }else{
                formdata.append("voiceTarget", fileTarget[0]);
            }
  
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            
            fetch(`${process.env.REACT_APP_BE}voice-cloning/call`, requestOptions)
                .then((response) => {
                    if(response.ok) return response.json()
                    else{
                        alert(`Oops Sorry, any trouble... please Reload :) `);
                        setStatus("Done");
                        setSpamClick(false);
                    }
                })
                .then((result) => { 
                    // console.log(result);
                    if(result !== undefined){
                        setAnswer(result.audio); 
                    }
                    
                    setStatus("Done");
                    setSpamClick(false)
                })
                .catch((error) => console.error(error));
        }

    }


    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Voice Cloning</p>
                </div>

                <div className="contentCapability" id="errorContext">
                    <p className="titleCapability">See How Voice Cloning Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Voice Cloning technology through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorContext ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >                    
                            <label>Media Upload (Input Context)</label>
                            

                            <Dropzone onDrop= {acceptedFiles => {
                                setFileContext( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }} accept={{'audio/mpeg': [], 'audio/wav': []}} maxSize={2000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileContext([]); setExampleContext(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: MP3, WAV</p>
                                <p className="informationUploadCapability">Maximum size: 2MB</p>
                            </div>

                            {fileContext.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={mp3} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileContext([]); setExampleContext(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                    <audio src={item.preview} controls/>
                                </div>
                            ))}

                            <p className="noMargin" id="errorTarget"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorContext ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your audio Context :))
                            </p>

                        </div>
                        <div className="textAreaCapability">
                            <label>Copy Example</label>
                            <audio src={cewe} controls/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileContext([{preview: cewe, name: 'ExampleContext.mp3', size: 0}]); setExampleContext(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>  
                            <label>Media Upload (Input Target)</label>

                            <Dropzone onDrop= {acceptedFiles => {
                                setFileTarget( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }} accept={{'audio/mpeg': [], 'audio/wav': []}} maxSize={2000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileTarget([]); setExampleTarget(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: MP3, WAV</p>
                                <p className="informationUploadCapability">Maximum size: 2MB</p>
                            </div>

                            {fileTarget.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={mp3} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                    <audio src={item.preview} controls/>
                                </div>
                            ))}

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your audio Target :))
                            </p>

                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <audio src={cowo} controls/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileTarget([{preview: cowo, name: 'ExampleTarget.mp3', size: 0}]); setExampleTarget(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitCloning()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                                {status === "Done" ? (
                                    <audio src={answer} controls/>
                                ) : (
                                    <>
                                    {status === "Loading" ? (
                                        <img src={loading} style={{width: '100%'}} alt="up"></img>
                                    ) : (
                                        <p>{status}</p>
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                </div>



            </section>

            <Footer/>
        </>
    )
}

export default VoiceCloning