import '../../../assets/style/HPC3.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ab1 from "../../../assets/photo/about1.webp";
import ab2 from "../../../assets/photo/about2.webp";
import ab3 from "../../../assets/photo/about3.webp";

import ic1 from "../../../assets/icon/icon1.svg";
import ic2 from "../../../assets/icon/icon2.svg";
import ic3 from "../../../assets/icon/icon3.svg";

function HPC3() {
    const listAbout = [{
        src: ic1,
        text: "High Performance AI Specialist"
    },
    {
        src: ic2,
        text: "Clean Codes and Costumized Deliverables"
    },
    {
        src: ic3,
        text: "Complete Projects Quickly and on Time"
    }];


    return(
        <section className='HCP3'>
            <div className='leftHCP3'>
                <img src={ab1} className='imageAbout' alt="ab1"></img>
                <div className='leftBottomHCP3'>
                    <div>
                    <   img src={ab2} className='imageAbout' alt="ab2"></img>
                    </div>
                    <div>
                        <img src={ab3} className='imageAbout' alt="ab3"></img>
                    </div>
                </div>
            </div>
            <div className='rightHCP3'>
                <h2>We're on a mission to <br/>
                bring ideas to life.</h2>
                <p className='textHCP3'>Our mission is to facilitate seamless AI transformation, helping users harness the power of artificial intelligence to drive innovation, efficiency, and growth.</p>
                <div className='listAIHCP3 goneMobile'>
                    {listAbout.map((item, index) => (
                        <div key={index} className='AIHCP3'>
                            <img src={item.src} alt="icon"></img>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>

                <div className="listAIHCP3Mobile">
                    <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlaySpeed={2000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 8,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                            }
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                        autoPlay={true}
                    >
                        {listAbout.map((item, index) => (
                            <div key={index} className='AIHCP3'>
                                <img src={item.src} alt="icon"></img>
                                <p>{item.text}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </section>
    )
}

export default HPC3;