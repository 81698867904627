import '../../assets/style/Topbar.css';

import logo from "../../assets/photo/Logo.webp";
import our1 from "../../assets/icon/our1.svg";
import our2 from "../../assets/icon/our2.svg";
import our3 from "../../assets/icon/our3.svg";
import our4 from "../../assets/icon/our4.svg";

import bb from "../../assets/icon/burgerBar.svg";

import { Link } from 'react-router-dom';
import { useState } from 'react'

function Topbar(params) {
    const [dropdown, setDropdown] = useState(false);
    const [menu, setMenu] = useState(false);
    const active = params.state;

    window.onclick = function(event){
        if(event.target.className === "downTopbar" && menu === false ){
            setDropdown(!dropdown);
        } else {
            setDropdown(false);
        }

        if(event.target.className === "mobileIconTopbar" || event.target.className === "iconTopbar"){
            setMenu(!menu);
        } else {
            
            if(event.target.className === "downTopbar"){
                setDropdown(!dropdown);
            } else {
                setMenu(false);
            }
            
        }
    };

    const listOur = [{
        icon: our1,
        title: "AI Solutions",
        text: 'Use human intelligence to create artificial intelligence that can solve many problems for example with CV or NLP.',
        link: '/demo'
    },
    {
        icon: our2,
        title: "Software Development",
        text: 'Create and develop applications to support your business with us. Web apps and mobile apps will be very helpful.',
        link: '/software'
    },
    {
        icon: our3,
        title: "Big Data Intelligence",
        text: 'Nowadays big data is something important for companies, intelligence to  manage it is a must. So we are here to bring a solution for that.',
        link: '/bigdata'
    },
    {
        icon: our4,
        title: "Data Analytics",
        text: 'Increase revenue, operational efficiency, and optimize marketing  programs and customer service efforts by analyzing existing data so that  it becomes useful information.',
        link: '/data'
    }];

    const balikAtas = () => {
        document.body.scrollIntoView({
            behavior: "smooth",
        });
    };

    return(
        <header className='topbar' id='Topbar'>
            <section>
                <Link to={"/"}><img src={logo} className='logoTopbar' alt="Logo" onClick={() => balikAtas() }></img></Link>
            </section>
            <section className='menuTopbar goneMobile'>
                <ul className='menuNavBarTopbar'>
                    <ol>
                        <Link to={"/"} className={active === "Homepage" ? "activeTopbar" : ""} onClick={() => balikAtas() }>Home</Link>
                    </ol>
                    <ol>
                        <Link className='downTopbar'>
                            Our Service
                        </Link>
                        <div className={dropdown ? 'dropdownTopbar' : 'dropdownTopbar noneTopbar'}>
                            {listOur.map((item, index) => (
                                <Link key={index} to={item.link} className='itemOurTopbar' onClick={() => balikAtas()}>
                                    <img src={item.icon} alt='icon'/> 
                                    <div className='ketOurTopbar'>
                                        <p className='titleOurTopbar'>{item.title}</p>
                                        <p className='textOurTopbar'>{item.text}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </ol>
                    <ol>
                        <Link to={"/about-us"} className={active === "About Us" ? "activeTopbar" : ""} onClick={() => balikAtas() }>About Us</Link>
                    </ol>
                    <ol>
                        <a href={'#Footer'} className="ContactUs" >Contact Information</a>
                    </ol>
                </ul>
                <Link to={"/contact-us"} onClick={() => balikAtas() }>
                    <button className='buttonTopbar'>
                        Free Consultation
                    </button>
                </Link>

            </section>

            {/* Mobile */}
            <section className='mobileIconTopbar' >
                <img src={bb} className='iconTopbar' alt="what?"/>
                <div className={menu ? 'listMenuTopbar' : 'listMenuTopbar'} style={{opacity: !menu ? "0" : "1", transition: "all .2s", visibility: !menu ? "hidden" : "visible"}}>
                    <Link to={"/"} className={active === "Homepage" ? "activeTopbar" : ""} onClick={() => balikAtas() }>Home</Link>
                    <Link className='downTopbar'>
                        Our Service
                    </Link>
                    <div className={dropdown ? 'ls' : 'ls noneTopbar'} >
                    {listOur.map((item, index) => (
                        <Link key={index} to={item.link} className='itemOurTopbar' onClick={() => balikAtas() }>
                            <div className='ketOurTopbar'>
                                <p className='titleOurTopbar'>{item.title}</p>
                            </div>
                        </Link>
                    ))}
                    </div>
                    <Link to={"/about-us"} className={active === "About Us" ? "activeTopbar" : ""} onClick={() => balikAtas() }>About Us</Link>
                    <a href={'#Footer'} className="ContactUs" >Contact Information</a>
                    <Link to={"/contact-us"} onClick={() => balikAtas() }>
                        <button className='buttonTopbar'>
                            Free Consultation
                        </button>
                    </Link>
                </div>
            </section>
        </header>
    );
};

export default Topbar;