import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";

import "../../assets/style/TermPolicy.css"

function Term(params) {
    return(
        <>
        <Topbar/>

        <section className="termpolicy">
            <div className="containter">
                <p className="titleTermPolicy">Term Of Service</p>
                <p className="subTitleTermPolicy">Syarat dan Ketentuan Aplikasi Mobile Future Link</p>
                <p className="textTermPolicy">Selamat datang di Future Link! Harap membaca dengan cermat Syarat dan  Ketentuan (selanjutnya disebut “Syarat”) berikut ini sebelum Anda mulai  menggunakan aplikasi mobile Future Link (selanjutnya disebut “Aplikasi”)  dan layanan yang terkait (selanjutnya disebut “Layanan”). Dengan  mengunduh, mengakses, atau menggunakan Aplikasi ini, Anda setuju untuk  tunduk pada Syarat ini. Jika Anda tidak setuju dengan Syarat ini,  silahkan untuk tidak menggunakan Aplikasi Future Link.</p>

                <p className="subTitleTermPolicy">Penerimaan Syarat</p>
                <ol>
                    <li>Penggunaan Aplikasi ini tunduk pada persetujuan Anda terhadap Syarat ini</li>
                </ol>

                <p className="subTitleTermPolicy">Pendaftaran Akun</p>
                <ol>
                    <li>Anda mungkin diminta untuk membuat akun untuk mengakses fitur tertentu di Aplikasi.</li>
                    <li>Anda bertanggung jawab untuk menjaga kerahasiaan informasi akun dan kata sandi Anda.</li>
                    <li>Anda  harus berusia minimal 13 tahun untuk membuat akun. Jika Anda berusia di  bawah 13 tahun, Anda memerlukan izin dari orang tua atau wali Anda  untuk menggunakan Aplikasi ini.</li>
                </ol>

                <p className="subTitleTermPolicy">Konten Pengguna</p>
                <ol>
                    <li>Aplikasi ini memungkinkan Anda untuk mengunggah gambar dari kamera Anda atau berbagi konten lainnya.</li>
                    <li>Anda  bertanggung jawab atas semua konten yang Anda unggah dan berjanji untuk  tidak mengunggah materi yang melanggar hak cipta, hak pribadi, atau  hukum yang berlaku.</li>
                </ol>

                <p className="subTitleTermPolicy">Pembelian dalam Aplikasi</p>
                <ol>
                    <li>Aplikasi ini menawarkan pembelian dalam Aplikasi untuk layanan tambahan yang disediakan oleh Future Link.</li>
                    <li>Pembayaran akan dibebankan ke metode pembayaran yang Anda pilih yang terkait dengan akun toko Aplikasi Anda.</li>
                    <li>Anda dapat membatalkan pembelian dalam Aplikasi sesuai dengan kebijakan toko Aplikasi Anda.</li>
                </ol>

                <p className="subTitleTermPolicy">Privasi dan Perlindungan Data</p>
                <ol>
                    <li>Kami menghormati privasi Anda. Informasi lebih lanjut tentang  pengumpulan dan penggunaan data Anda dapat ditemukan dalam Kebijakan  Privasi kami.</li>
                </ol>

                <p className="subTitleTermPolicy">Tanggung Jawab Pengguna</p>
                <ol>
                    <li>Anda setuju untuk tidak menggunakan Aplikasi ini untuk tujuan ilegal atau yang melanggar Syarat ini.</li>
                    <li>Anda bertanggung jawab atas setiap tindakan yang terjadi pada akun Anda.</li>
                </ol>

                <p className="subTitleTermPolicy">Perubahan pada Layanan</p>
                <ol>
                    <li>Future Link berhak untuk mengubah, menangguhkan, atau menghentikan  Layanan atau bagian dari Layanan kapan saja tanpa pemberitahuan  sebelumnya.</li>
                </ol>

                <p className="subTitleTermPolicy">Pelanggaran Syarat</p>
                <ol>
                    <li>Pelanggaran terhadap Syarat ini dapat mengakibatkan penghentian akses Anda ke Aplikasi dan Layanan kami.</li>
                </ol>

                <p className="subTitleTermPolicy">Kontak Darurat</p>
                <ol>
                    <li>Kami tidak mendukung atau mengendalikan konten yang diunggah oleh  pengguna. Jika Anda menemukan konten yang melanggar hukum atau tidak  pantas, silakan hubungi kami di hello@futurelink.id untuk melaporkannya.</li>
                </ol>

                <p className="subTitleTermPolicy">Hukum yang Berlaku</p>
                <ol>
                    <li>Syarat ini diatur oleh dan diinterpretasikan sesuai dengan hukum di Indonesia.</li>
                </ol>

                <p className="subTitleTermPolicy">Perubahan pada Syarat</p>
                <ol>
                    <li>Kami berhak untuk memperbarui Syarat ini dari waktu ke waktu. Perubahan  akan diberitahukan kepada Anda melalui Aplikasi atau email.</li>
                </ol>

                <p className="subTitleTermPolicy">Kontak Kami</p>
                <p className="textTermPolicy">Jika Anda memiliki pertanyaan atau komentar, silakan hubungi kami di:</p>
                <ol>
                    <li > Alamat: Simprug Plaza Blok B2 Nomor 8, Cikarang Baru, Kab. Bekasi, Prov. Jawa Barat</li>
                    <li > Telepon: +62 859-6020-6799</li>
                    <li > Email: hello@futurelink.id</li>
                </ol>
                <p className="textTermPolicy">Dengan menggunakan Aplikasi Future Link, Anda setuju untuk tunduk pada Syarat ini. Harap baca dengan cermat dan tinjau secara berkala untuk melihat pembaruan yang ada.</p>
            </div>
        </section>

        <Footer/>
        </>
    )
}

export default Term;