import "../../assets/style/DataAnalytics.css";

import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";
import checklist from "../../assets/icon/checklist.svg";
import checklistSize from "../../assets/icon/checklistSize.svg";

import bg from "../../assets/photo/dataBackground.webp"


function DataAnalytics(params) {

    const listitem = [
        "Descriptive Analytics",
        "Diagnostic Analytics",
        "Predictive Analytics",
        "Prescriptive Analytics",
        "Real-Time Analytics",
        "Data Visualization",
        "Customer Analytics",
        "Operational Analytics"
    ]

    const listwhy = [{
        title: "Expertise",
        text: "Our team of data scientists and engineers brings extensive experience and cutting-edge skills.",
    },
    {
        title: "Customization",
        text: "Tailored solutions that align with your business needs and goals.",
    },
    {
        title: "Advanced Tools",
        text: "Leverage the latest technologies and analytical tools to gain accurate insights.",
    },
    {
        title: "Scalability",
        text: "Solutions designed to grow with your business, handling increasing volumes of data seamlessly.",
    },
    {
        title: "Data Security",
        text: "Robust security measures to protect your sensitive data.",
    },
    {
        title: "Continuous Support",
        text: "Ongoing assistance and support to ensure you get the most out of your analytics.",
    },
    ]

    return(
        <>
            <Topbar/>

            <section className='data'>
                <div className='mainData'>
                    <img src={bg} className='backgroundMainData' alt="background"/>
                    <div className='contentMainData'>
                        <h1 className='white'>Data Analytics</h1>
                    </div>

                    {/* <div className='contentMainBigMobile'>
                        <h1 className='white'>Data Analytics</h1>
                    </div> */}
                </div>
            </section>

            <section className="contentBig">
                <div className="width70">
                    <h1>Transform Data into Actionable Insights</h1>
                    <p className="textNormal mb40">Increase revenue, boost operational efficiency, and optimize marketing programs and customer service efforts by analyzing your existing data and turning it into useful information.</p>
                
                    <p className="titleNormal">Data Analytics Solutions</p>
                    <p className="textNormal">Unlock the full potential of your data with our comprehensive data analytics services. We offer:</p>

                    {listitem.map((item, index) => (
                    <div key={index} className="itemBig">
                        <div style={{display: 'flex'}} alt="check">
                            <img src={checklist} alt="what?"/>
                        </div>
                        <p className="textNormal textAlignStart">{item}</p>
                    </div>
                    ))}

                    <p className="titleNormal">Why Choose Our Data Analytics Services?</p>

                    {listwhy.map((item, index) => (
                    <div key={index} className="itemWhy">
                        <div style={{display: 'flex'}}>
                            <img src={checklistSize} alt="check"/>
                        </div>
                        <div>
                        <h2 className="titleItemWhyBig">{item.title}</h2>
                        <h2 className="textItemWhyBig">{item.text}</h2>
                        </div>
                    </div>
                    ))}
                </div>

                <div className="cardSoftware">
                    <p className="titleHigh">Ready to Transform Your Business?</p>
                    <p className="textNormal mb64">Take the first step towards innovation and growth. Schedule your free consultation today and see how INFIDEA can turn your ideas into reality.</p>
                    <div>
                        <button className="buttonBlack" onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>Let's Collaborate!</button>
                    </div>
                    
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default DataAnalytics