import '../../../assets/style/HPC5.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import linkin from "../../../assets/icon/linkin.svg";
import linkinBlue from "../../../assets/icon/linkinBlue.svg";
import person1 from "../../../assets/photo/person1.webp";
import person2 from "../../../assets/photo/person2.webp";
import person3 from "../../../assets/photo/person3.webp";
import person4 from "../../../assets/photo/person4.webp";
import person5 from "../../../assets/photo/person5.webp";
import person6 from "../../../assets/photo/person6.webp";
import person7 from "../../../assets/photo/person7.webp";
import person8 from "../../../assets/photo/person8.webp";
import person9 from "../../../assets/photo/person9.webp";

function HPC5() {

    const listPerson1 = [
        {
          img: person1,
          nama: "Teja Kusuma",
          job: 'CEO',
          link: 'https://www.linkedin.com/in/tejakusuma/'
        },
        {
          img: person2,
          nama: "Alexander Calvin",
          job: 'Security Engineer',
          link: 'https://www.linkedin.com/in/alexander-calvin-3539201a4/'
        },
        {
          img: person3,
          nama: "Ahmad Saladin",
          job: 'Data Engineer',
          link: 'https://www.linkedin.com/in/ahmad-saladin-a71a41192/'
        }
    ];
    
    const listPerson2 = [
        {
          img: person4,
          nama: "Redho Darmawan",
          job: 'Software Engineer',
          link: 'https://www.linkedin.com/in/redho-darmawan-77383818a/'
        },
        {
          img: person5,
          nama: "M. Qubaisy Andiantama",
          job: 'Software Engineer',
          link: 'https://www.linkedin.com/in/qubaisy/'
        },
        {
          img: person6,
          nama: "Rizky Muliawan B.",
          job: 'Software Engineer',
          link: 'https://www.linkedin.com/in/faravra/'
        }
    ];
    
    const listPerson3 = [
        {
          img: person7,
          nama: "Adikara Perkasa B. T.",
          job: 'QA Engineer',
          link: 'https://www.linkedin.com/in/adikaraperkasa/'
        },
        {
          img: person8,
          nama: "Nugi G. Yasa",
          job: 'Project Manager',
          link: 'https://www.linkedin.com/in/nugigyasa/'
        },
        {
          img: person9,
          nama: "M. Surya Alam",
          job: 'Product Designer',
          link: 'https://www.linkedin.com/in/suryaalam/'
        }
    ];

    const listPerson = [
      {
        img: person1,
        nama: "Teja Kusuma",
        job: 'CEO',
        link: 'https://www.linkedin.com/in/tejakusuma/'
      },
      {
        img: person2,
        nama: "Alexander Calvin",
        job: 'Security Engineer',
        link: 'https://www.linkedin.com/in/alexander-calvin-3539201a4/'
      },
      {
        img: person3,
        nama: "Ahmad Saladin",
        job: 'Data Engineer',
        link: 'https://www.linkedin.com/in/ahmad-saladin-a71a41192/'
      },
      {
        img: person4,
        nama: "Redho Darmawan",
        job: 'Software Engineer',
        link: 'https://www.linkedin.com/in/redho-darmawan-77383818a/'
      },
      {
        img: person5,
        nama: "M. Qubaisy Andiantama",
        job: 'Software Engineer',
        link: 'https://www.linkedin.com/in/qubaisy/'
      },
      {
        img: person6,
        nama: "Rizky Muliawan B.",
        job: 'Software Engineer',
        link: 'https://www.linkedin.com/in/faravra/'
      },
      {
        img: person7,
        nama: "Adikara Perkasa B. T.",
        job: 'QA Engineer',
        link: 'https://www.linkedin.com/in/adikaraperkasa/'
      },
      {
        img: person8,
        nama: "Nugi G. Yasa",
        job: 'Project Manager',
        link: 'https://www.linkedin.com/in/nugigyasa/'
      },
      {
        img: person9,
        nama: "M. Surya Alam",
        job: 'Product Designer',
        link: 'https://www.linkedin.com/in/suryaalam/'
      }
    ]

    return(
        <section className="HCP5">

            <div>
                <div className='ourTeamHCP5'>
                    <h2>Our Team</h2>
                    <p>With diverse skills and deep industry experience, we drive your AI transformation to help your business thrive in the digital age.</p>
                </div>
                <div></div>
            </div>

            <div>
                <div className="listPersonHCP5 goneMobile">
                    {listPerson1.map((item, index) => (
                        <div key={index} className="cardPersonHCP5">
                            <img src={item.img} className="imgPersonHCP5" alt="person"></img>
                            <p className='namaHCP5'>{item.nama}</p>
                            <p className='jobHCP5'>{item.job}</p>
                            <a href={item.link} target='_blank' rel='noreferrer' className='linkedHCP5'>
                              <img src={linkin} alt='linkedin' className='linkGoneHCP5'></img>
                              
                              <div className="overlayHCP5">
                                <img src={linkinBlue} alt='linkedin'></img>
                              </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="listPersonHCP5 goneMobile">
                    {listPerson2.map((item, index) => (
                        <div key={index} className="cardPersonHCP5">
                            <img src={item.img} className="imgPersonHCP5" alt="person"></img>
                            <p className='namaHCP5'>{item.nama}</p>
                            <p className='jobHCP5'>{item.job}</p>
                            <a href={item.link} target='_blank' rel='noreferrer' className='linkedHCP5'>
                              <img src={linkin} alt='linkedin' className='linkGoneHCP5'></img>
                              
                              <div className="overlayHCP5">
                                <img src={linkinBlue} alt='linkedin'></img>
                              </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="listPersonHCP5 goneMobile">
                    {listPerson3.map((item, index) => (
                        <div key={index} className="cardPersonHCP5">
                            <img src={item.img} className="imgPersonHCP5" alt="person"></img>
                            <p className='namaHCP5'>{item.nama}</p>
                            <p className='jobHCP5'>{item.job}</p>
                            <a href={item.link} target='_blank' rel='noreferrer' className='linkedHCP5'>
                              <img src={linkin} alt='linkedin' className='linkGoneHCP5'></img>
                              
                              <div className="overlayHCP5">
                                <img src={linkinBlue} alt='linkedin'></img>
                              </div>
                            </a>
                        </div>
                    ))}
                </div>

                <div className='listPersonHCP5Mobile'>
                  <Carousel
                      additionalTransfrom={0}
                      arrows={false}
                      autoPlaySpeed={10000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      responsive={{
                          desktop: {
                              breakpoint: {
                                  max: 3000,
                                  min: 1024
                              },
                              items: 8,
                              partialVisibilityGutter: 40
                          },
                          mobile: {
                              breakpoint: {
                                  max: 464,
                                  min: 0
                              },
                              items: 1,
                              partialVisibilityGutter: 30
                          },
                          tablet: {
                          breakpoint: {
                              max: 1024,
                              min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 30
                          }
                      }}
                      rewind={false}
                      rewindWithAnimation={false}
                      rtl={false}
                      shouldResetAutoplay
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      autoPlay={true}
                  >
                    {listPerson.map((item, index) => (
                      <div key={index} className="cardPersonHCP5">
                          <img src={item.img} className="imgPersonHCP5" alt="person"></img>
                          <p className='namaHCP5'>{item.nama}</p>
                          <p className='jobHCP5'>{item.job}</p>
                          <a href={item.link} target='_blank' rel='noreferrer' className='linkedHCP5'>
                            <img src={linkin} alt='linkedin' className='linkGoneHCP5'></img>
                            
                            <div className="overlayHCP5">
                              <img src={linkinBlue} alt='linkedin'></img>
                            </div>
                          </a>
                      </div>
                    ))}
                  </Carousel>
                  
                </div>
            </div>
        </section>
    );
};

export default HPC5;