import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import upload from "../../../assets/icon/upload.svg";
import x from "../../../assets/icon/x.svg";

import target from "../../../assets/photo/blueGrape.jpg";
import context1 from "../../../assets/photo/blackGrape.jpg";
import context2 from "../../../assets/photo/apple.jpg";

import Dropzone from 'react-dropzone'
import { useState } from "react";
import { Link } from "react-router-dom";


function ImageSimilarity(params) {
    const [fileContext, setFileContext] = useState([]);
    const [fileTarget, setFileTarget] = useState([]);
    const [exampleContext, setExampleContext] = useState(false)
    const [exampleTarget, setExampleTarget] = useState(false)
    const [answer, setAnswer] = useState([{image: "", distance: ""}]);
    const [status, setStatus] = useState("")
    const [spamClick, setSpamClick] = useState(false)
    const [errorContext, setErrorContext] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);

    function formatBytes(bytes) {
        if (bytes < 1024) return bytes + ' Bytes';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes < 1024 * 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        else return (bytes / (1024 * 1024 * 1024)).toFixed(6) + ' GB';
    }

    const submitSimilarity = async () => {
        setStatus("")

        if(fileContext.length === 0 || fileTarget.length === 0){

            if(fileContext.length === 0) {
                // setStatus("Please input your image Context :))")

                const link = document.createElement("a");
                link.href = "#errorContext";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorContext(true);
                setTimeout(() => {
                    setErrorContext(false)
                }, 4000);
            }
            if(fileTarget.length === 0) {
                // setStatus("Please input your image Target :))")

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }

        } else if (exampleContext && exampleTarget) {
            const example = [{img: context1, distance: 0.89}, {img: context2, distance: 0.23}]
            setAnswer(example);
            setStatus("Done");
        } else {
            setStatus("Loading");
            setSpamClick(true);
            const formdata = new FormData();

            if(exampleContext) {
                const response1 = await fetch(context1);
                const blob1 = await response1.blob();
                const file1 = new File([blob1], "anggur.jpg", { type: "image/jpg" })

                const response2 = await fetch(context2);
                const blob2 = await response2.blob();
                const file2 = new File([blob2], "apple.jpg", { type: "image/jpg" })

                formdata.append("imageCollection", file1);
                formdata.append("imageCollection", file2);
            } else {
                // eslint-disable-next-line 
                fileContext.map((item) => {
                    formdata.append("imageCollection", item);
                })
            }
            
            if(exampleTarget) {
                const responsetarget = await fetch(target);
                const blobtarget = await responsetarget.blob();
                const file = new File([blobtarget], "anggurr.jpg", { type: "image/jpg" })

                formdata.append("imageInput", file);
            } else {
                formdata.append("imageInput", fileTarget[0]);
            }
    
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
    
            fetch(`${process.env.REACT_APP_BE}similarity/check`, requestOptions)
            .then((response) => {
                if(response.ok) return response.json()
                else{
                    alert(`Oops Sorry, any trouble... please Reload :) `);
                    setStatus('');
                    setSpamClick(false);
                }
            })
            .then((result) => {
                // console.log(result)
                // console.log(result.result)
                // console.log(result.result.results)

                if(result.result.success){
                    cocokinGambar(fileContext, result.result.results)
                }else{
                    alert(`Oops Sorry, any trouble... please Reload :) `);
                    setStatus("Done")
                }
                
                setSpamClick(false)
                
            })
            .catch((error) => console.error(error));
        }
    }

    
    function cocokinGambar (context, target) {
        // console.log(context, target)
        let array = context
        let hasil = target

        let akhir = []
        for (let i = 0; i < array.length; i++) {

            for (let k = 0; k < array.length; k++) {

                if (array[i].name === hasil[k].image) {
                    akhir.push({img: array[i].preview, distance: (hasil[k].distance).toFixed(2)})
                }
                
            }
            
        }
        // console.log(akhir)
        setAnswer(akhir)
        setStatus("Done")
    }
    

    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Image Similarity</p>
                </div>

                <div className="contentCapability" id="errorContext">
                    <p className="titleCapability">See How Custom Image Similarity Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Image Similarity technology through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorContext ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >  
                            <label>Media Upload (Input Context)</label>
                            
                            <Dropzone onDrop= {acceptedFiles => {
                                setFileContext( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }}  accept={{'image/jpeg': [], 'image/png': []}} maxSize={1000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileContext([]); setExampleContext(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: JPEG, JPG, PNG</p>
                                <p className="informationUploadCapability">Maximum size: 1MB</p>
                            </div>

                            {fileContext.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={item.preview} width={120} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileContext([]); setExampleContext(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                </div>
                            ))}

                            <p className="noMargin" id="errorTarget"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorContext ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your image Context :))
                            </p>

                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <img src={context1} width={120} alt="up"/>
                            <img src={context2} width={120} alt="up"/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileContext([{preview: context1, name: 'anggur.jpg', size: 0},{preview: context2, name: 'apple.jpg', size: 0}]); setExampleContext(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>               
                            <label>Media Upload (Input Target)</label>
                            

                            <Dropzone onDrop= {acceptedFiles => {
                                setFileTarget( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }}  accept={{'image/jpeg': [], 'image/png': []}} maxSize={1000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileTarget([]); setExampleTarget(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: JPEG, JPG, PNG</p>
                                <p className="informationUploadCapability">Maximum size: 1MB</p>
                            </div>

                            {fileTarget.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={item.preview} width={120} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                </div>
                            ))}

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your image Target :))
                            </p>

                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <img src={target} width={120} alt="up"/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileTarget([{preview: target, name: 'anggurr.jpg', size: 0}]); setExampleTarget(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitSimilarity()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                            {status === "Done" ? (
                                <>
                                {answer.map((item, index) => (
                                <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                                    <img src={item.img} width={80} alt="up"></img>
                                    <progress value={item.distance} max="1"></progress>
                                    <p style={{wordBreak: 'auto-phrase'}}>{item.distance}</p>
                                </div>
                                ))}
                                </>
                            ) : (
                                <>
                                {status === "Loading" ? (
                                    <img src={loading} style={{width: '100%'}} alt="up"></img>
                                ) : (
                                    <p>{status}</p>
                                )}
                                </>
                            )}

                            </div>
                        </div>
                    </div>

                </div>



            </section>

            <Footer/>

        </>
    )
}

export default ImageSimilarity