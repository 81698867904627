import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import '../../../assets/style/HPC4.css';

import aiic1 from "../../../assets/icon/aiic1.svg";
import aiic2 from "../../../assets/icon/aiic2.svg";
import aiic3 from "../../../assets/icon/aiic3.svg";
import aiic4 from "../../../assets/icon/aiic4.svg";
import aiic5 from "../../../assets/icon/aiic5.svg";
import aiic6 from "../../../assets/icon/aiic6.svg";
import aiic7 from "../../../assets/icon/aiic7.svg";

function HPC4(params) {

    const listCapability = [{
        tag: "Language",
        title: "Natural Language Processing",
        text: "Leverage advanced NLP for precise Question Answering, News extraction, and Fewshot learning with minimal data.",
        icon: aiic1,
        link: "blabla"
    },
    {
        tag: "Voice",
        title: "Voice Cloning",
        text: "Create realistic voice replicas for virtual assistants, audiobooks, and more with our Voice Cloning technology.",
        icon: aiic2,
        link: "blabla"
    },
    {
        tag: "Voice",
        title: "Voice Separation",
        text: "Isolate individual voices from mixed audio tracks, ideal for editing, transcription, and communication clarity.",
        icon: aiic3,
        link: "blabla"
    },
    {
        tag: "Image",
        title: "Custom Image Classification",
        text: "Efficiently categorize and identify objects in images with our customizable image recognition solutions.",
        icon: aiic4,
        link: "blabla"
    },
    {
        tag: "Image",
        title: "Image Similarity",
        text: "Enhance search engines and photo libraries with fast, accurate visually similar image detection.",
        icon: aiic5,
        link: "blabla"
    },
    {
        tag: "Image",
        title: "Optical Character Recognition",
        text: "Convert printed text into digital format with our OCR feature. Seamlessly transform e-statements into editable and searchable data, enhancing workflow efficiency and data accessibility.",
        icon: aiic6,
        link: "blabla"
    },
    {
        tag: "Design",
        title: "3D",
        text: "Create rigging 3D models for design, gaming, and virtual reality applications.",
        icon: aiic7,
        link: "blabla"
    }];

    return(
        <section className="HCP4">
            <h2 className='white'>Simple, yet Powerful AI Features</h2>
        
            <div className="listCardHCP4">
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={4000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                        },
                        mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    autoPlay={true}
                >
                    {listCapability.map((item, index) => (
                        <div key={index} className="cardAIHCP4"> 
                            <div>
                                <div className="tagCardHCP4">{item.tag}</div>
                                <p className="titleCardHCP4">{item.title}</p>
                                <p className="textCardHCP4">{item.text}</p>
                            </div>

                            <div className="mb24">
                                <hr/>
                                <div className="bottomCardHCP4">
                                    <img src={item.icon} alt="icon"></img>
                                    <button className="buttonSubmit" disabled>Try Demo</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </section>
    );
};

export default HPC4;