// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUC2{
    display: flex;
    gap: 32px;
    align-self: center;
    width: 78%;
    margin-top: 10%;
}

.rightAUC2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
}

.textAUC2{
    margin: 0px;
    color: #808080;
    font-size: 24px;
    font-weight: 400;
}

.photoAUC2{
    display: flex;
    gap: 24px;
    width: 100%;
}

.tambahfotoAUC2Mobile{
    display: none;
}


@media screen and (max-width: 1280px) {
    .AUC2{
        flex-direction: column;
    }

    .tambahfotoAUC2Mobile{
        margin-top: 16px;
        display: flex;
        gap: 16px
    }

    .rightAUC2 h1{
        font-size: 28px;
        text-align: center;
    }

    .textAUC2{
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/AUC2.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;IACT,WAAW;AACf;;AAEA;IACI,WAAW;IACX,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,gBAAgB;QAChB,aAAa;QACb;IACJ;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".AUC2{\n    display: flex;\n    gap: 32px;\n    align-self: center;\n    width: 78%;\n    margin-top: 10%;\n}\n\n.rightAUC2{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 32px;\n    width: 100%;\n}\n\n.textAUC2{\n    margin: 0px;\n    color: #808080;\n    font-size: 24px;\n    font-weight: 400;\n}\n\n.photoAUC2{\n    display: flex;\n    gap: 24px;\n    width: 100%;\n}\n\n.tambahfotoAUC2Mobile{\n    display: none;\n}\n\n\n@media screen and (max-width: 1280px) {\n    .AUC2{\n        flex-direction: column;\n    }\n\n    .tambahfotoAUC2Mobile{\n        margin-top: 16px;\n        display: flex;\n        gap: 16px\n    }\n\n    .rightAUC2 h1{\n        font-size: 28px;\n        text-align: center;\n    }\n\n    .textAUC2{\n        font-size: 18px;\n        line-height: 28px;\n        text-align: justify;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
