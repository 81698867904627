// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data{
    margin: 168px 0% 0% 0%;
}
.mainData{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.backgroundMainData{
    width: 100%;
}
.contentMainData{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    text-align: center;
}

/* .contentMainDataMobile{
    display: none;
} */

@media screen and (max-width: 1280px) {
    /* .contentMainDataMobile{
        display: flex;
        margin-top: 24px;
    } */

    .contentMainData .white{
        font-size: 26px !important;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/DataAnalytics.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,kBAAkB;AACtB;;AAEA;;GAEG;;AAEH;IACI;;;OAGG;;IAEH;QACI,0BAA0B;QAC1B,kBAAkB;IACtB;AACJ","sourcesContent":[".data{\n    margin: 168px 0% 0% 0%;\n}\n.mainData{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n.backgroundMainData{\n    width: 100%;\n}\n.contentMainData{\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 92%;\n    text-align: center;\n}\n\n/* .contentMainDataMobile{\n    display: none;\n} */\n\n@media screen and (max-width: 1280px) {\n    /* .contentMainDataMobile{\n        display: flex;\n        margin-top: 24px;\n    } */\n\n    .contentMainData .white{\n        font-size: 26px !important;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
