import "../../../assets/style/AUC1.css";
import bg from "../../../assets/photo/aboutUsBackground.webp";

function AUC1(params) {
    return (
        <section className='AUC1'>
            <div className='mainAUC1'>
                <img src={bg} className='backgroundMainAUC1 goneMobile' alt="background"/>
                <div className='contentMainAUC1 goneMobile'>
                    <h1 className='white'>About Us</h1>
                </div>

                <div className="contentMainAUC1Mobile">
                    <h1 className='white'>About Us</h1>
                </div>
            </div>
        </section>
    );
};

export default AUC1;