// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termpolicy{
    margin: 168px 0% 80px 0%;
}
.termpolicy .containter{
    padding-left: 10%;
    padding-right: 25%;
}
.termpolicy .containter .titleTermPolicy{
    font-size: 32px;
    font-weight: 700;
    color: #242331;
}
.termpolicy .containter .subTitleTermPolicy{
    font-size: 18px;
    font-weight: 700;
    color: #242331;
    margin-top: 48px;
}
.termpolicy .containter .textTermPolicy{
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 8px;
    color: #242331; 
}
.termpolicy .containter ol{
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 8px;
    color: #242331; 
    list-style-type: inherit;
    padding-left: 24px;
    cursor: inherit;
}
.termpolicy .containter ol li{
    margin-bottom: 8px;
    cursor: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/TermPolicy.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,wBAAwB;IACxB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".termpolicy{\n    margin: 168px 0% 80px 0%;\n}\n.termpolicy .containter{\n    padding-left: 10%;\n    padding-right: 25%;\n}\n.termpolicy .containter .titleTermPolicy{\n    font-size: 32px;\n    font-weight: 700;\n    color: #242331;\n}\n.termpolicy .containter .subTitleTermPolicy{\n    font-size: 18px;\n    font-weight: 700;\n    color: #242331;\n    margin-top: 48px;\n}\n.termpolicy .containter .textTermPolicy{\n    font-size: 18px;\n    font-weight: 400;\n    text-align: justify;\n    margin-top: 0px;\n    margin-bottom: 8px;\n    color: #242331; \n}\n.termpolicy .containter ol{\n    font-size: 18px;\n    font-weight: 400;\n    text-align: justify;\n    margin-top: 0px;\n    margin-bottom: 8px;\n    color: #242331; \n    list-style-type: inherit;\n    padding-left: 24px;\n    cursor: inherit;\n}\n.termpolicy .containter ol li{\n    margin-bottom: 8px;\n    cursor: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
