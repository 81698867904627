import '../../../assets/style/HPC2.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import pb1 from "../../../assets/photo/pb1.webp";
import pb2 from "../../../assets/photo/pb2.webp";
import pb3 from "../../../assets/photo/pb3.webp";
import pb4 from "../../../assets/photo/pb4.webp";
import pb5 from "../../../assets/photo/pb5.webp";
import pb6 from "../../../assets/photo/pb6.webp";
import pb7 from "../../../assets/photo/pb7.webp";
import pb8 from "../../../assets/photo/pb8.webp";

function HPC2() {

    const listPartner = [pb1,pb2,pb3,pb4,pb5,pb6,pb7,pb8]

    return(
        <section className='HCP2'>

            <div className="listPartnerHCP2">
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={2000}
                    centerMode={false}
                    className="gapSetengah"
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 8,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 3,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                    autoPlay={true}
                >
                    {listPartner.map((item, index) => (
                        <div key={index}>
                            <img src={item} className='width100' alt="partner"></img>
                        </div>
                    ))}
                </Carousel>
            </div>

      </section>
    )
}

export default HPC2;