import "../../../assets/style/AUC3.css";

import ic1 from "../../../assets/icon/icon1.svg";
import ic2 from "../../../assets/icon/icon2.svg";
import ic3 from "../../../assets/icon/icon3.svg";

function AUC3(params) {
    const listAbout = [{
        src: ic1,
        text: "High Performance AI Specialist",
        ket: "We have experts in AI consisting of data scientists, machine learning,  and engineers solve your problems and build solutions for you."
    },
    {
        src: ic2,
        text: "Clean Codes and Costumized Deliverables",
        ket: "We deliver web app, mobile apps, and custom project with quality."
    },
    {
        src: ic3,
        text: "Complete Projects Quickly and on Time",
        ket: "We have optimized our technology stack and team collaboration to deliver results faster."
    }];

    return (
        <section className="AUC3">
            {listAbout.map((item, index) => (
            <div key={index} className="listAIAUC3">
                <div>
                    <img src={item.src} alt="AI"></img>
                </div>
                <div className="writeAUC3">
                    <p className="titleAUC3">{item.text}</p>
                    <p className="textAUC3">{item.ket}</p>
                </div>
            </div>
            ))}
        </section>
    );
};

export default AUC3;