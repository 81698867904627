import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";

import "../../assets/style/TermPolicy.css"

function Privacy(params) {
    return(
        <>
        <Topbar/>

        <section className="termpolicy">
            <div className="containter">
                <p className="titleTermPolicy">Privacy Policy</p>
                <p className="textTermPolicy">PT Rekayasa Teknologi Cerdas adalah pemilik konten dan operator dari situs www.infidea.id (“Situs”) dan aplikasi Future Link (“Aplikasi”) serta segala layanan yang disediakan di dalamnya dan layanan lain yang mungkin disediakan Infidea dari waktu ke waktu (“Layanan”).</p>
                <p className="textTermPolicy">Kami  menghargai privasi data dan informasi pribadi dan perusahaan anda,  selaku pengguna dan pelanggan Layanan (“Pelanggan”, “Anda”), serta  berkomitmen untuk melindungi data dan informasi Anda. Kebijakan privasi  ini menjelaskan bagaimana kami mengumpulkan, mengolah, menggunakan dan  mengungkapkan data dan informasi Anda (“Kebijakan Privasi”).</p>
                <p className="textTermPolicy">Anda memahami bahwa untuk memberikan Layanan yang terbaik untuk Anda, Infidea  dapat melakukan integrasi Layanan dengan sistem, aplikasi atau  perangkat lunak pihak ketiga. Sehubungan dengan integrasi tersebut,  Kebijakan Privasi ini tidak berlaku terhadap sistem, aplikasi atau  perangkat lunak pihak ketiga manapun tersebut yang terintegrasi dengan  Layanan, atau segala produk, jasa atau usaha lainnya milik pihak ketiga.  Mengingat sistem, aplikasi atau perangkat lunak pihak ketiga tersebut  mungkin memiliki kebijakan privasi yang berbeda, kami menyarankan agar  Anda membaca kebijakan privasi tersebut sebelum menggunakan Layanan yang  terintegrasi dengan sistem, aplikasi atau perangkat lunak pihak ketiga.  Anda juga memahami bahwa Informasi Pelanggan (sebagaimana didefinisikan  di bawah) yang mungkin diungkapkan kepada mitra pihak ketiga tersebut  berdasarkan Kebijakan Privasi ini, setelah diungkapkan, akan berada di  luar kendali kami.</p>

                <p className="subTitleTermPolicy">PENGAKUAN DAN PERSETUJUAN ANDA</p>
                <p className="textTermPolicy">Anda wajib membaca Kebijakan Privasi ini sebelum mendaftarkan diri  dan menggunakan Layanan. Dengan mendaftarkan diri dan menggunakan setiap  produk dan/atau Layanan kami, Anda menyatakan bahwa Anda telah membaca,  memahami dan setuju terhadap ketentuan-ketentuan Kebijakan Privasi ini.</p>
                <p className="textTermPolicy">Pengakuan  dan persetujuan Anda terhadap Kebijakan Privasi ini juga berkaitan  dengan ketentuan yang tercantum berdasarkan Ketentuan Penggunaan yang  dianggap sebagai satu kesatuan dan tidak dapat dipisahkan.</p>

                <p className="subTitleTermPolicy">PENGUMPULAN INFORMASI PELANGGAN</p>
                <p className="textTermPolicy">Infidea berhak mengumpulkan dan mengelola informasi  Pelanggan pada saat Pelanggan melakukan pendaftaran dan pembuatan akun  pada Layanan, pada saat Pelanggan menyediakan informasi sebagai bagian  dari proses verifikasi identitas, pada saat dilakukannya transaksi  dan/atau digunakannya Layanan, pada saat Pelanggan meminta tanda terima  secara digital, atau pada saat Pelanggan mengunggah atau mengirimkan  informasi mengenai produk-produk atau jasa-jasa Pelanggan (termasuk  benda-benda, harga-harga, dan data lainnya)</p>
                <p className="textTermPolicy">Jenis informasi yang kami kumpulkan dan kelola ketika berinteraksi dengan Anda sehubungan dengan Layanan, yakni mencakup:</p>
                <ol>
                    <li>Identitas, termasuk nama, jenis kelamin, tanggal lahir, usia, nama akun dan kata sandi.</li>
                    <li>Kontak, termasuk alamat sekolah/kantor, alamat email, dan nomor telepon.</li>
                    <li>Transaksi, termasuk rincian pembayaran rincian lain terkait produk dan layanan yang Anda gunakan atau beli dari kami.</li>
                    <li>Pemasaran dan Komunikasi, termasuk preferensi Anda dalam menerima materi pemasaran dan komunikasi dari kami dan mitra kami.</li>
                    <li>Data dan Informasi apapun yang diunggah oleh Anda dalam penggunaan Layanan.</li>
                    <li>Informasi yang melekat pada Anda saat Anda mengakses Situs, Aplikasi dan/atau Layanan.</li>
                    <li>Setiap informasi tertentu dari Anda yang dicatat dan dikumpulkan secara otomatis oleh Infidea dengan menggunakan berbagai tipe teknologi pelacakan, seperti alamat Protokol Internet (IP address), peralatan yang unik atau identitas pengguna (user ID), versi dari perangkat lunak yang digunakan, tipe sistem, tanggal, waktu, dan detil dari transaksi-transaksi Anda, geo tagging (geo-location), dan informasi dari akun Anda yang disediakan untuk publik oleh Anda;</li>
                    <li>Saran  dari Pelanggan, diskusi-diskusi komunitas (masyarakat), obrolan-obrolan  dan kegiatan interaksi lainnya dalam Situs dan/atau Aplikasi.</li>
                </ol>
                <p className="textTermPolicy">(Seluruh jenis informasi di atas selanjutnya disebut sebagai “Informasi Pelanggan”).</p>
                <p className="textTermPolicy">Infidea berhak, dari waktu ke waktu, melakukan  verifikasi terhadap Informasi Pelanggan atau informasi lainnya yang Anda  berikan kepada kami, dengan mengirimkan surat verifikasi, email, atau mengharuskan Anda untuk mengirimkan dokumentasi pendukung, atau cara lain apapun, sebagaimana yang diminta oleh Infidea.</p>
                <p className="textTermPolicy">Anda memahami dan mengakui bahwa Informasi Pelanggan dan/atau data dan  informasi lain yang Anda berikan dalam penggunaan Layanan dapat  merupakan, mengandung dan/atau berkaitan dengan data dan informasi milik  pihak lain. Anda bertanggung jawab untuk dan menyatakan serta menjamin  bahwa Anda telah mendapatkan izin untuk memberikan data dan informasi  pihak lain tersebut kepada Infidea. Anda dengan ini  membebaskan Infidea dari segala klaim, tuntutan dan/atau kerugian yang  timbul dari dan/atau sehubungan dengan pemberian data dan informasi  pihak lain tersebut oleh Anda.</p>


                <p className="subTitleTermPolicy">PENGGUNAAN INFORMASI PELANGGAN</p>
                <p className="textTermPolicy">Informasi Pelanggan dan informasi lainnya yang Anda berikan dan jika  relevan, untuk penggunaan, atau berlangganan, atau pembelian Layanan  dan/atau produk kami, termasuk informasi tambahan yang selanjutnya Anda  berikan, dapat digunakan dan diolah oleh kami untuk tujuan berikut:</p>
                <ol>
                    <li > Menjalankan usaha kami dan membantu kami dalam menyediakan, menjaga, dan meningkatkan Layanan.</li>
                    <li > Mengirimkan informasi dan mendukung permintaan Infidea, termasuk tanda terima, pengingat, dan pesan dukungan serta pesan administrasi.</li>
                    <li > Mengirimkan  kepada Anda, berita dan informasi tentang Layanan serta  mengkomunikasikan kepada Anda mengenai produk, jasa, promosi, dan  informasi terbaru yang ditawarkan Infidea dan mitra-mitra yang ditunjuknya.</li>
                    <li > Memberikan beberapa pilihan mengenai kegunaan informasi kami yang sesuai dengan Anda.</li>
                    <li > Memberikan penjelasan yang terbuka dan jelas mengenai bagaimana kami menggunakan informasi tersebut.</li>
                    <li > Mempublikasikan  atau membagikan informasi yang telah dikombinasi dari beberapa pengguna  dan/atau pelanggan, namun tentu saja dengan cara tidak akan membiarkan  Anda atau orang lain teridentifikasi.</li>
                    <li > Mengagregasikan data akun  Anda, yang sudah diunggah dan non-personal sehingga Anda tidak dapat  diidentifikasi, dengan data milik pengguna Layanan lain untuk  meningkatkan kualitas pelayanan, merancang promosi atau memberikan cara  bagi Anda untuk membandingkan praktek bisnis dengan pengguna lainnya.</li>
                    <li > Untuk  memperoleh dan mengumpulkan Informasi Pelanggan, serta menyimpan  Informasi Pelanggan dalam suatu sistem elektronik yang dimiliki oleh Infidea atau pihak ketiga.</li>
                    <li > Menilai dan memproses permintaan Anda terkait Layanan.</li>
                    <li > Menetapkan dan melakukan verifikasi atas identitas dan latar belakang Anda.</li>
                    <li > Membangun komunikasi antara kami dan Pelanggan.</li>
                    <li > Memproses transaksi pembayaran Anda terkait dengan Layanan.</li>
                    <li > Menanggapi pertanyaan, keluhan atau komentar dari Anda.</li>
                    <li > Mengelola partisipasi Pelanggan dalam acara atau program yang diselenggarakan Infidea.</li>
                    <li > Mengolah dan menganalisis Informasi Pelanggan, termasuk untuk melaksanakan analisis pasar, baik yang dilakukan oleh Infidea atau pihak ketiga.</li>
                    <li > Menampilkan,  mengumumkan dan membuka akses Informasi Pelanggan kepada anak  perusahaan, afiliasi, perusahaan terkait, pemegang lisensi, mitra usaha  dan/atau penyedia Layanan.</li>
                    <li > Menyelidiki dan mencegah penipuan atau aktivitas ilegal lainnya.</li>
                    <li > Melakukan kegiatan internal, termasuk investigasi internal, kepatuhan, audit dan keperluan keamanan internal lainnya.</li>
                    <li > Kegiatan usaha sah lainnya dari Infidea; dan Tujuan lainnya yang akan diungkapkan kepada Anda sehubungan dengan Layanan.</li>
                </ol>
                <p className="textTermPolicy"> (secara bersama-sama, “Tujuan”).</p>

                <p className="textTermPolicy"> Catatan transaksi dan lokasi Pelanggan dapat digunakan sebagai informasi  non-pribadi untuk membuat data statistik secara umum mengenai perilaku  pembelian dari populasi dimana Pelanggan merupakan bagian di dalamnya,  untuk menyediakan layanan yang lebih baik.</p>

                <p className="subTitleTermPolicy">PENGUNGKAPAN INFORMASI PELANGGAN</p>
                <p className="textTermPolicy">Anda memahami dan menyetujui bahwa kami mungkin memberikan dan/atau  mengungkapkan Informasi Pelanggan dengan cara-cara yang terbatas:</p>
                <ol>
                    <li > Guna memungkinkan kami melakukan Tujuan sebagaimana dijelaskan  di atas, kami mungkin memberikan dan/atau mengungkapkan Informasi  Pelanggan kepada anak perusahaan, afiliasi, perusahaan terkait, pemegang  lisensi, mitra usaha, penyedia layanan kami, penasihat profesional dan  auditor eksternal kami, termasuk penasihat hukum, penasihat keuangan dan  konsultan-konsultan, serta pihak ketiga lainnya, yang mungkin terletak  di dalam atau di luar Indonesia.</li>
                    <li > Kami mungkin menyediakan fitur  Layanan yang menghubungkan Anda dengan mitra usaha, penyedia layanan  atau pihak ketiga lainnya, dan dengan demikian kami dapat mengungkapkan  beberapa Informasi Pelanggan secara terbatas ke mitra usaha, penyedia  layanan atau pihak ketiga lainnya tersebut hanya untuk keperluan  pelaksanaan fitur Layanan tersebut.</li>
                    <li > Kami mungkin terlibat atau  mempekerjakan perusahaan atau perseorangan lain untuk memfasilitasi,  memberikan layanan-layanan tertentu atau melakukan fungsi atas nama  kami, dan sehubungan dengan hal tersebut kami mungkin memberikan  dan/atau mengungkapkan Informasi Pelanggan kepada perusahaan atau  perseorangan lain tersebut.</li>
                    <li > Apabila terjadi suatu transaksi  perusahaan, termasuk namun tidak terbatas pada, penjualan anak  perusahaan atau divisi, peleburan, konsolidasi, pembiayaan, penjualan  aset atau situasi lain apapun yang melibatkan pemindahan sebagian atau  seluruh aset bisnis kami, kami mungkin mengungkapkan Informasi Pelanggan  kepada pihak-pihak yang terlibat di dalam perundingan atau pemindahan  tersebut.</li>
                    <li > Kami mungkin juga mengungkapkan Informasi Pelanggan  apabila diwajibkan secara hukum, atau diperlukan untuk tunduk pada  ketentuan peraturan perundang-undangan, peraturan-peraturan dan  pemerintah, atau dalam hal terjadi sengketa, atau segala bentuk proses  hukum terkait dengan Layanan, atau dalam keadaan darurat yang berkaitan  dengan kesehatan dan/atau keselamatan Anda.</li>
                    <li > Sejauh yang  diperbolehkan hukum yang berlaku, kami juga dapat mengungkapkan  Informasi Pelanggan atas permintaan dari lembaga penegak hukum atau  badan pemerintah lainnya, atau apabila kami merasa bahwa pengungkapan  tersebut dapat mencegah terjadi suatu tindakan kriminal, atau dapat  membantu penyelidikan sehubungan dengan keselamatan orang banyak, untuk  melindungi keamanan atau integritas dari Situs dan Aplikasi, atau untuk  membuat kami dapat mengambil tindakan pencegahan atas kemungkinan adanya  kerugian.</li>
                    <li > Kami mungkin juga memberikan informasi himpunan atau anonim yang tidak secara langsung mengidentifikasikan Anda.</li>
                </ol>
                <p className="textTermPolicy">Selain untuk hal tersebut di atas, Infidea tidak akan  menjual, menyewakan, atau secara lain mengungkapkan Informasi Pelanggan  kepada pihak lain tanpa persetujuan dari Anda.</p>

                <p className="subTitleTermPolicy">KEAMANAN INFORMASI PELANGGAN</p>
                <p className="textTermPolicy">Infidea menjaga keamanan Informasi Pelanggan dengan serius. Infidea  bekerja keras untuk melindungi Informasi Pelanggan yang Anda berikan  dari kehilangan, penyalahgunaan, dan pengungkapan atau akses yang tidak  berwenang.</p>
                <p className="textTermPolicy">Dalam menjaga keamanan Informasi Pelanggan, kami telah  menggunakan metode-metode terbaik yang telah teruji untuk melindungi  Informasi Pelanggan, meninjau kembali prosedur keamanan kami secara  hati-hati, mematuhi hukum dan standar keamanan yang berlaku, memastikan  bahwa Informasi Pelanggan terkirim dengan aman.</p>
                <p className="textTermPolicy">Kami akan  mengambil langkah-langkah yang diperlukan untuk mempertahankan privasi  dan keamanan dari seluruh Informasi Pelanggan yang Anda berikan. Dalam  hal terjadi peretasan yang berada di luar kendali kami, Anda setuju  untuk membebaskan Infidea dari seluruh klaim, tanggung jawab hukum ataupun pengeluaran apapun yang muncul dari peretasan tersebut.</p>

                <p className="subTitleTermPolicy">PERUBAHAN</p>
                <p className="textTermPolicy">Kebijakan Privasi ini dapat diubah setiap saat dari waktu ke waktu  dan akan berlaku pada tanggal yang ditentukan oleh kami. Setiap  perubahan atas Kebijakan Privasi ini akan dipublikasikan di Situs  dan/atau Aplikasi atau media lainnya yang dianggap perlu oleh kami. Mohon baca dan telusuri Kebijakan Privasi di Situs dan/atau Aplikasi  secara berkala untuk melihat perubahan-perubahan tersebut. Dengan terus  menggunakan setiap produk dan/atau Layanan kami setelah publikasi  tersebut atau pemberitahuan dari kami, Anda dianggap setuju terhadap  perubahan terhadap Kebijakan Privasi ini.</p>

                <p className="subTitleTermPolicy">HUKUM DAN YURSIDIKSI YANG BERLAKU</p>
                <p className="textTermPolicy">Kebijakan Privasi ini diatur oleh dan untuk ditafsirkan berdasarkan  hukum Negara Republik Indonesia. Setiap dan seluruh sengketa yang timbul  dari Kebijakan Privasi ini akan diselesaikan pertama-tama melalui  musyawarah untuk mufakat dalam waktu 30 (tiga puluh) hari terhitung  sejak diterimanya pemberitahuan timbulnya sengketa. Apabila setelah  jangka waktu dimaksud tidak tercapai kesepakatan, maka penyelesaian  konflik akan diselesaikan oleh Badan Arbitrase Nasional Indonesia (BANI).</p>

                <p className="subTitleTermPolicy">HUBUNGI KAMI</p>
                <p className="textTermPolicy">Anda dapat menyampaikan pertanyaan atau klarifikasi apapun terkait dengan Kebijakan Privasi ini ke bagian pelayanan pelanggan (Customer Support) kami di infidea@infidea.id atau melalui aplikasi whatsapp pada nomor +62 859 6020 6799</p>

            </div>
        </section>

        <Footer/>
        </>
    )
}

export default Privacy;