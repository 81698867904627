import "../../../assets/style/AUC4.css"

import pb1 from "../../../assets/photo/pb1.webp"
import pb2 from "../../../assets/photo/pb2.webp"
import pb3 from "../../../assets/photo/pb3.webp"
import pb4 from "../../../assets/photo/pb4.webp"
import pb5 from "../../../assets/photo/pb5.webp"
import pb6 from "../../../assets/photo/pb6.webp"
import pb7 from "../../../assets/photo/pb7.webp"
import pb8 from "../../../assets/photo/pb8.webp"

function AUC4(params) {
    const listPartner1 = [pb1,pb2,pb3,pb4]
    const listPartner2 = [pb5,pb6,pb7,pb8]
    
    return (
        <section className="AUC4">
            <h1>Our Client</h1>
            <div className="listPartnerAUC4">
                <div className="posisiAUC4">
                    {listPartner1.map((item, index) => ((
                        <div className="width100">
                            <img key={index} src={item} className="width100" alt="partner"></img>
                        </div>
                        
                    )))}
                </div>
                <div className="posisiAUC4">
                    {listPartner2.map((item, index) => ((
                        <div className="width100">
                            <img key={index} src={item} className="width100" alt="partner"></img>
                        </div>
                    )))}
                </div>
            </div>
        </section>
    )
}

export default AUC4;