import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Homepage from "./page/Homepage/Homepage.js";
import ContactUs from "./page/ContactUs/ContactUs.js";
import AboutUs from './page/AboutUs/AboutUs.js';
import Demo from './page/Demo/Demo.js';
import SoftwareDev from './page/SoftwareDevelopment/SoftwareDevelopment.js';
import BigData from './page/BigData/BigData.js';
import DataAnalytics from './page/DataAnalytics/DataAnalytics.js';
import Term from './page/TermPolicy/Term.js';
import Privacy from './page/TermPolicy/Privacy.js';


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage/>,
    },
    {
      path: '/demo',
      element: <Demo/>
    },
    {
      path: '/software',
      element: <SoftwareDev/>
    },
    {
      path: '/bigdata',
      element: <BigData/>
    },
    {
      path: '/data',
      element: <DataAnalytics/>
    },
    {
      path: "/about-us",
      element: <AboutUs/>,
    },
    {
      path: "/contact-us",
      element: <ContactUs/>
    },
    {
      path: "/term-of-service",
      element: <Term/>,
    },
    {
      path: "/privacy-policy",
      element: <Privacy/>
    },
    {
      path: "*",
      element: <Homepage/>
    }
  ]);
  

  return (
    <div className='page'>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
