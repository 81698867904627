// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUC1{
    margin: 168px 4% 0% 4%;
}
.mainAUC1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.backgroundMainAUC1{
    width: 100%;
}
.contentMainAUC1{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    text-align: center;
}

.contentMainAUC1Mobile{
    display: none;
}

@media screen and (max-width: 1280px){
    .contentMainAUC1Mobile{
        display: flex;
        margin-top: 24px;
    }
    .contentMainAUC1Mobile .white{
        color: #2F2F2F;
        font-size: 32px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/AUC1.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,gBAAgB;IACpB;IACA;QACI,cAAc;QACd,eAAe;IACnB;AACJ","sourcesContent":[".AUC1{\n    margin: 168px 4% 0% 4%;\n}\n.mainAUC1{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n.backgroundMainAUC1{\n    width: 100%;\n}\n.contentMainAUC1{\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 92%;\n    text-align: center;\n}\n\n.contentMainAUC1Mobile{\n    display: none;\n}\n\n@media screen and (max-width: 1280px){\n    .contentMainAUC1Mobile{\n        display: flex;\n        margin-top: 24px;\n    }\n    .contentMainAUC1Mobile .white{\n        color: #2F2F2F;\n        font-size: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
