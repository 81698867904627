import Topbar from "../../component/Topbar/Topbar.js";
import HPC1 from "./Content1/HPC1.js";
import HPC2 from "./Content2/HPC2.js";
import HPC3 from "./Content3/HPC3.js";
import HPC4 from "./Content4/HPC4.js";
import HPC5 from "./Content5/HPC5.js";
import Footer from "../../component/Footer/Footer";

function Homepage() {
    const active = "Homepage";

    return(
        <>
            <Topbar state={active}/>
            <HPC1/>
            <HPC2/>
            <HPC3/>
            <HPC4/>
            <HPC5/>
            <Footer/>
        </>
    );
};

export default Homepage;