import '../../../assets/style/AUC2.css';

import ab1 from "../../../assets/photo/about1.webp";
import ab2 from "../../../assets/photo/about2.webp";
import ab3 from "../../../assets/photo/about3.webp";


function AUC2(params) {

    return(
        <section className="AUC2">
            <div className="width100">
                <img src={ab1} className="imageAbout" alt="AboutUs"></img>

                <div className='tambahfotoAUC2Mobile'>
                    <div>
                        <img src={ab2} className='imageAbout' alt="ab2"></img>
                    </div>
                    <div>
                        <img src={ab3} className='imageAbout' alt="ab3"></img>
                    </div>
                </div>
            </div>
            <div className="rightAUC2">
                <h1>We're on a mission to
                bring ideas to life.</h1>
                <p className="textAUC2">Our mission is to facilitate seamless AI transformation, helping users harness the power of artificial intelligence to drive innovation, efficiency, and growth.</p>
                <div className="photoAUC2 goneMobile">
                    <div>
                        <img src={ab2} className="imageAbout" alt="AboutUs"/>
                    </div>
                    <div>
                        <img src={ab3} className="imageAbout" alt="AboutUs"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AUC2;