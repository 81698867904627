// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUC4{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 128px;
    margin: 8% 0px;
}

.listPartnerAUC4{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.posisiAUC4{
    display: flex;
    align-self: center;
    width: 80%;
    gap: 24px
}

@media screen and (max-width: 1280px) {
    .AUC4{
        margin: 56px 0px;
        gap: 40px;
    }
    .AUC4 h1{
        font-size: 32px;
    }
    .posisiAUC4{
        width: 100%;
        gap: 24px
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/AUC4.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,SAAS;IACb;IACA;QACI,eAAe;IACnB;IACA;QACI,WAAW;QACX;IACJ;AACJ","sourcesContent":[".AUC4{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 128px;\n    margin: 8% 0px;\n}\n\n.listPartnerAUC4{\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n.posisiAUC4{\n    display: flex;\n    align-self: center;\n    width: 80%;\n    gap: 24px\n}\n\n@media screen and (max-width: 1280px) {\n    .AUC4{\n        margin: 56px 0px;\n        gap: 40px;\n    }\n    .AUC4 h1{\n        font-size: 32px;\n    }\n    .posisiAUC4{\n        width: 100%;\n        gap: 24px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
