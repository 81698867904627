// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HCP2{
    display: flex;
    margin-top: 20%;
}

.listPartnerHCP2{
    width: 100%;
    margin-top: 3%;
    margin-bottom: 6%;
}`, "",{"version":3,"sources":["webpack://./src/assets/style/HPC2.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".HCP2{\n    display: flex;\n    margin-top: 20%;\n}\n\n.listPartnerHCP2{\n    width: 100%;\n    margin-top: 3%;\n    margin-bottom: 6%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
