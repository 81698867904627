import "../../assets/style/SoftwareDevelopment.css"

import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";
import checklist from "../../assets/icon/checklist.svg";

import bg from "../../assets/photo/softwareBackground.webp"


function SoftwareDev(params) {

    const listWeb = [
        "Custom Development",
        "Responsive Design",
        "E-commerce Integration",
        "Content Management Systems (CMS)",
        "API Integration",
        "Security",
        "Performance Optimization",
        "Maintenance & Support",
    ];

    const listMobile = [
        "iOS & Android Development",
        "Cross-Platform Solutions",
        "User-Centric Design",
        "Push Notifications",
        "In-App Purchases",
        "Offline Functionality",
        "Data Synchronization",
        "Testing & QA",
        "Deployment & Maintenance"
    ];

    return(
        <>
            <Topbar/>
        
            <section className='software'>
                <div className='mainSoftware'>
                    <img src={bg} className='backgroundMainSoftware' alt="background"/>
                    <div className='contentMainSoftware'>
                        <h1 className='white'>Software Development</h1>
                    </div>

                    {/* <img src={bg} className='backgroundMainSoftware' alt="background"/>
                    <div className='contentMainSoftwareMobile'>
                        <h1 className='white'>Software Development</h1>
                    </div> */}
                </div>
            </section>

            <section className="contentSoftware">
                <div className="width70">
                    <h1>Transform Your Vision into Reality</h1>
                    <p className="textNormal mb40">Create and develop applications to support your business with us. Our expert team is dedicated to building custom solutions that cater to your specific needs.   </p>

                    <p className="titleNormal">Web App</p>
                    <p className="textNormal">Enhance your online presence and streamline operations with our feature-rich web applications. Our web app services include:</p>

                    {listWeb.map((item, index) => (
                        <div key={index} className="itemSoftware">
                            <div style={{display: 'flex'}}>
                                <img src={checklist} alt="check"/>
                            </div>
                            <p className="textNormal textAlignStart">{item}</p>
                        </div>
                    ))}

                    <p className="titleNormal">Mobile App</p>
                    <p className="textNormal">Reach your customers on-the-go with responsive and user-friendly mobile applications. Our mobile app services include:</p>

                    {listMobile.map((item, index) => (
                        <div key={index} className="itemSoftware">
                            <div style={{display: 'flex'}}>
                                <img src={checklist} alt="check"/>
                            </div>
                            <p className="textNormal textAlignStart">{item}</p>
                        </div>
                    ))}

                </div>

                <div className="cardSoftware">
                    <p className="titleHigh">Ready to Transform Your Business?</p>
                    <p className="textNormal mb64">Take the first step towards innovation and growth. Schedule your free consultation today and see how INFIDEA can turn your ideas into reality.</p>
                    <div>
                        <button className="buttonBlack" onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>Let's Collaborate!</button>
                    </div>
                    
                </div>
            </section>

            <Footer/>
        </>
    );
};

export default SoftwareDev;