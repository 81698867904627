import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import { Link } from "react-router-dom";
import { useState } from "react";


function QA(params) {
    const [context, setContext] = useState("")
    const [question, setQuestion] = useState("")
    const [status, setStatus] = useState("Done")
    const [answer, setAnswer] = useState("")
    const [errorContext, setErrorContext] = useState(false);
    const [errorQuestion, setErrorQuestion] = useState(false);

    const submitQA = () => {

        if(context === "" || question === "") {

            if(context === "") {
                // setAnswer("Input your Context please :))");
            
                const link = document.createElement("a");
                link.href = "#errorContext";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorContext(true);
                setTimeout(() => {
                    setErrorContext(false)
                }, 4000);
            }
            if(question === "") {
                // setAnswer("Input your Question please :))");

                const link = document.createElement("a");
                link.href = "#errorQuestion";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorQuestion(true);
                setTimeout(() => {
                    setErrorQuestion(false)
                }, 4000);
            }

        } else if (context === "Chief Executive for Supervision of Financing Institutions, Venture Capital Companies, Micro Financing Institutions and Other Financial Services Institutions, the Financial Services Authority (OJK) Agusman also said that the potential for using online loans continues to increase. Financing growth from fintech lending companies reaches 26% every year and is the highest growth compared to any financial industry" && question === "Who says the potential for using online loans is increasing?") {
            setAnswer("Chief%20Executive%20for%20Supervision%20of%20Financing%20Institutions,%20Venture%20Capital%20Companies,%20Micro%20Financing%20Institutions%20and%20Other%20Financial%20Services%20Institutions,%20the%20Financial%20Services%20Authority%20(OJK)%20Agusman%20also%20said%20that%20the%20potential%20for%20using%20online%20loans%20continues%20to%20increase")
        } else {
            setStatus("Loading")

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };
            
            fetch(`${process.env.REACT_APP_BE}qa/question?question=${question}&context=${context}`, requestOptions)
            .then((response) => {
                if(response.ok) return response.json()
                else{
                    alert(`Oops Sorry, any trouble... please Reload :) `);
                    setStatus("Done")
                }
            })
                .then((result) => {
                    // console.log(result); 
                    setAnswer(result.result);
                    setStatus("Done")
                })
                .catch((error) => console.error(error));
        }

    }

    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/nlp"}>NLP</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Question Answer</p>
                </div>

                <div className="contentCapability" id="errorContext">
                    <p className="titleCapability">See How Question Answering NLP Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Question Answering NLP through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorContext ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >                 
                            <label>Input Knowledge</label>
                            <textarea placeholder="Input your knowledge." value={context} onChange={(event) => {setContext(event.target.value)}}/>

                            <p className="noMargin" id="errorQuestion"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorContext ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your Context :))
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <p className="textExampleCapability" onClick={() => setContext("Chief Executive for Supervision of Financing Institutions, Venture Capital Companies, Micro Financing Institutions and Other Financial Services Institutions, the Financial Services Authority (OJK) Agusman also said that the potential for using online loans continues to increase. Financing growth from fintech lending companies reaches 26% every year and is the highest growth compared to any financial industry")}>Chief Executive for Supervision of Financing Institutions, Venture Capital Companies, Micro Financing Institutions and Other Financial Services Institutions, the Financial Services Authority (OJK) Agusman also said that the potential for using online loans continues to increase. Financing growth from fintech lending companies reaches 26% every year and is the highest growth compared to any financial industry </p>
                            <a href="https://finance.detik.com/fintech/d-7478591/129-juta-orang-ri-punya-pinjaman-online-total-rp-874-triliun" target="_blank" rel='noreferrer '>https://finance.detik.com/fintech/d-7478591/129-juta-orang-ri-punya-pinjaman-online-total-rp-874-triliun</a>
                        </div>
                    </div>

                    <div className="inputCapability">
                        <div className={errorQuestion ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >                     
                            <label>Question Answer</label>
                            <textarea placeholder="Ask your question." value={question} onChange={(event) => {setQuestion(event.target.value)}} />

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorQuestion ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your question :))
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <p className="textExampleCapability" onClick={() => setQuestion("Who says the potential for using online loans is increasing?")}>Who says the potential for using online loans is increasing?</p>
                        </div>
                    </div>

                    <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitQA()}>Submit</button>

                    <div>
                        <div className="textAreaCapability">                    
                            <label>Result</label>
                            <div className="resultCapability">
                                {status === "Done" ? (
                                <p>
                                    {answer.split("%20").join(' ')}
                                </p>
                                ) : (
                                    <img src={loading} style={{width: '100%'}} alt="up"></img>
                                )}


                            </div>
                        </div>
                    </div>

                </div>



            </section>

            <Footer/>
        </>
    )
}

export default QA