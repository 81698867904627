// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AUC3{
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin-top: 5%;
    margin-left: 11%;
}

.listAIAUC3{
    display: flex;
    align-items: center;
    gap: 48px;
    width: 55%;
}

.writeAUC3{
    display: flex;
    flex-direction: column;
    gap: 24px
}

.titleAUC3{
    margin: 0px;
    color: #2F2F2F;
    font-size: 32px;
    font-weight: 700;
    
    transition: 0.5s;
}

.titleAUC3:hover{
    color: #37C871;

    transition: 0.5s;
}

.textAUC3{
    margin: 0px;
    color: #808080;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
}

@media screen and (max-width: 1280px){
    .AUC3{
        margin-top: 40px;
        margin-left: 0;
    }

    .listAIAUC3{
        flex-direction: column;
        align-self: center;
        gap: 24px;
        width: 80%;
        text-align: center;
    }

    .textAUC3{
        font-size: 18px;
        text-align: center;
    }

    .titleAUC3{
        font-size: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/AUC3.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB;AACJ;;AAEA;IACI,WAAW;IACX,cAAc;IACd,eAAe;IACf,gBAAgB;;IAEhB,gBAAgB;AACpB;;AAEA;IACI,cAAc;;IAEd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;;IAEA;QACI,sBAAsB;QACtB,kBAAkB;QAClB,SAAS;QACT,UAAU;QACV,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".AUC3{\n    display: flex;\n    flex-direction: column;\n    gap: 56px;\n    margin-top: 5%;\n    margin-left: 11%;\n}\n\n.listAIAUC3{\n    display: flex;\n    align-items: center;\n    gap: 48px;\n    width: 55%;\n}\n\n.writeAUC3{\n    display: flex;\n    flex-direction: column;\n    gap: 24px\n}\n\n.titleAUC3{\n    margin: 0px;\n    color: #2F2F2F;\n    font-size: 32px;\n    font-weight: 700;\n    \n    transition: 0.5s;\n}\n\n.titleAUC3:hover{\n    color: #37C871;\n\n    transition: 0.5s;\n}\n\n.textAUC3{\n    margin: 0px;\n    color: #808080;\n    font-size: 20px;\n    font-weight: 400;\n    text-align: justify;\n}\n\n@media screen and (max-width: 1280px){\n    .AUC3{\n        margin-top: 40px;\n        margin-left: 0;\n    }\n\n    .listAIAUC3{\n        flex-direction: column;\n        align-self: center;\n        gap: 24px;\n        width: 80%;\n        text-align: center;\n    }\n\n    .textAUC3{\n        font-size: 18px;\n        text-align: center;\n    }\n\n    .titleAUC3{\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
