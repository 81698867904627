import Topbar from "../../component/Topbar/Topbar";
import AUC1 from "./Content1/AUC1.js";
import AUC2 from "./Content2/AUC2.js";
import AUC3 from "./Content3/AUC3.js";
import AUC4 from "./Content4/AUC4.js";
import Footer from "../../component/Footer/Footer";

function AboutUs(params) {
    const active = "About Us";

    return(
        <>
            <Topbar state={active}/>
            <AUC1/>
            <AUC2/>
            <AUC3/>
            <AUC4/>
            <Footer/>
        </>
    );
};

export default AboutUs;