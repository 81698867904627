import "../../assets/style/ContactUs.css"
import bg from "../../assets/photo/Background.webp"
import accept from "../../assets/icon/accept.svg"
import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";
import { useState } from "react";

import Modal from 'react-modal';


function ContactUs(params) {
    const [openC, setOpenC] = useState(false)

    const [contentAlert, setContentAlert] = useState("")

    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [subject, setSubject] = useState("")
    const [content, setContent] = useState("")

    const sendEmail = () => {

        if(fullname === ""){
            setContentAlert("Please input your fullname");
            setOpenC(true)
        } else if (email === ""){
            setContentAlert("Please input your email")
            setOpenC(true)
        } else if (company === ""){
            setContentAlert("Please input your company")
            setOpenC(true)
        } else if (subject === ""){
            setContentAlert("Please input the subject")
            setOpenC(true)
        } else if (content === ""){
            setContentAlert("Please input the content")
            setOpenC(true)
        } else {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
            const urlencoded = new URLSearchParams();
            urlencoded.append("fullName", fullname);
            urlencoded.append("company", company);
            urlencoded.append("subject", subject);
            urlencoded.append("content", content);
            urlencoded.append("email", email);
        
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
        
            fetch(`${process.env.REACT_APP_BE}contact/email`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);

                setOpenC(true)
    
                if(result.message === "info not completed"){
                    setContentAlert("Sorry , can't send email")
                } else {
                    setContentAlert("Thank you for submitting! Please wait a moment. We look forward to collaborating with your team!");
                    setTimeout(() => {
                        window.location.reload();
                    },2000)

                }
    
            })
            .catch((error) => console.error(error));
        }
    }

    return(
        <>
        <Topbar/>

        <section className='contactUs'>
            <div className='mainContactUs goneMobile'>
                <img src={bg} className='backgroundMainContactUs' alt="background"/>
                <div className='contentMainContactUs'>
                    
                    <div className="listFormContactUs">
                        <div className="formContactUs">
                            <label>Full Name *</label>
                            <input type="text" placeholder="John David" value={fullname} onChange={(event) => {setFullname(event.target.value)}}/>
                        </div>
                        <div className="formContactUs">
                            <label>Your Email *</label>
                            <input type="text" placeholder="example@mail.com" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                        </div>
                    </div>
                    <div className="listFormContactUs">
                    <div className="formContactUs">
                            <label>Company *</label>
                            <input type="text" placeholder="your company name here" value={company} onChange={(event) => {setCompany(event.target.value)}}/>
                        </div>
                        <div className="formContactUs">
                            <label>Subject *</label>
                            <input type="text" placeholder="how can we help" value={subject} onChange={(event) => {setSubject(event.target.value)}}/>
                        </div> 
                    </div>
                    <div className="formContactUs">
                        <label>Message *</label>
                        <textarea placeholder="Hello there, i would like to talk about how to. . ." value={content} onChange={(event) => {setContent(event.target.value)}}/>
                    </div>
                    <button className="buttonBlack" onClick={() => sendEmail()}>Send Message</button>
                
                </div>
            </div>

            <div className="contentMainContactUsMobile">
                <p>Free Consulation</p>

                <div className="listFormContactUs">
                    <div className="formContactUs">
                        <label>Full Name *</label>
                        <input type="text" placeholder="John David" value={fullname} onChange={(event) => {setFullname(event.target.value)}}/>
                    </div>
                    <div className="formContactUs">
                        <label>Your Email *</label>
                        <input type="text" placeholder="example@mail.com" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                    </div>
                </div>
                <div className="listFormContactUs">
                    <div className="formContactUs">
                        <label>Company *</label>
                        <input type="text" placeholder="your company name here" value={company} onChange={(event) => {setCompany(event.target.value)}}/>
                    </div>
                    <div className="formContactUs">
                        <label>Subject *</label>
                        <input type="text" placeholder="how can we help" value={subject} onChange={(event) => {setSubject(event.target.value)}}/>
                    </div> 
                </div>
                <div className="formContactUs">
                    <label>Message *</label>
                    <textarea placeholder="Hello there, i would like to talk about how to. . ." value={content} onChange={(event) => {setContent(event.target.value)}}/>
                </div>
                <button className="buttonBlack" onClick={() => sendEmail()}>Send Message</button>
                

            </div>
        </section>

        <Footer/>

        <Modal isOpen={openC} onRequestClose={() => setOpenC(!openC)} className="ModalContactUs" overlayClassName="OverlayFooter">
            <div className="notifikasiContactUs">
                <img src={accept} alt="what?"></img>
                <p className="noMargin">{contentAlert}</p>
                <button className="buttonGreenFill" onClick={() => setOpenC(!openC)}>OK</button>
            </div>
        </Modal>
        </>
    )
}

export default ContactUs;