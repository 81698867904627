import "../../../assets/style/Capability.css";

import Topbar from "../../../component/Topbar/Topbar"
import Footer from "../../../component/Footer/Footer"

import Modal from 'react-modal';

import arrow from "../../../assets/icon/arrowRight.svg";
import loading from "../../../assets/icon/loading.svg";
import edit from "../../../assets/icon/edit.svg";
import upload from "../../../assets/icon/upload.svg";
import x from "../../../assets/icon/x.svg";

import apple from "../../../assets/photo/apple.jpg";

import Dropzone from 'react-dropzone'
import { useState } from "react";
import { Link } from "react-router-dom";


function CustomImage(params) {
    const [open, setOpen] = useState(false);
    const [fileTarget, setFileTarget] = useState([])
    const [classification, setClassification] = useState([]);
    const [arrayDelete, setArrayDelete] = useState([]);
    const [valueText, setValueText] = useState("")
    const [indexText, setIndexText] = useState("")
    const [exampleTarget, setExampleTarget] = useState(false)
    
    const [condition, setCondition]= useState("")

    const [category, setCategory] = useState([])
    const [score, setScore] = useState([])

    const [status, setStatus] = useState('')

    const [spamClick, setSpamClick] = useState(false)
    const [errorClassification, setErrorClassification] = useState(false);
    const [errorTarget, setErrorTarget] = useState(false);



    function formatBytes(bytes) {
        if (bytes < 1024) return bytes + ' Bytes';
        else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + ' KB';
        else if (bytes < 1024 * 1024 * 1024) return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        else return (bytes / (1024 * 1024 * 1024)).toFixed(6) + ' GB';
    }

    const submitImage = async () => {
        setStatus("")

        if(classification.length === 0 || fileTarget.length === 0){

            if(classification.length === 0) {
                // setStatus("Please input your Classification :))");
                const link = document.createElement("a");
                link.href = "#errorClassification";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorClassification(true);
                setTimeout(() => {
                    setErrorClassification(false)
                }, 4000);

            }
            if(fileTarget.length === 0) {
                // setStatus("Please input your image Target :))");

                const link = document.createElement("a");
                link.href = "#errorTarget";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setErrorTarget(true);
                setTimeout(() => {
                    setErrorTarget(false)
                }, 4000);
            }

        } else if (exampleTarget === true && classification[0] === "Apple" && classification[1] === "Orange" && classification[2] === "Melon") {
            setCategory(["Apple","Orange","Melon"])
            setScore([99.59, 0.28, 0.11])
            setStatus("Done")
        } else {
            setStatus("Loading")
            setSpamClick(true)
            const formdata = new FormData();

            if (exampleTarget) {
                const responseapple = await fetch(apple);
                const blobapple = await responseapple.blob();
                const fileapple = new File([blobapple], "apple.jpg", { type: "image/jpg" })
                
                formdata.append("image", fileapple);
            } else {
                formdata.append("image", fileTarget[0]);
            }

            formdata.append("collectionCategories", JSON.stringify(classification));
            formdata.append("resultsAmount", classification.length);
            
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow"
            };
            
            fetch(`${process.env.REACT_APP_BE}classification/classify`, requestOptions)
                .then((response) => {
                    if(response.ok) return response.json()
                    else{
                        alert(`Oops Sorry, any trouble... please Reload :) `);
                        setStatus('');
                        setSpamClick(false);
                    }
                })
                .then((result) => {
                    // console.log(result);
                    // console.log(result.result);
                    if(result.result.success){
                        setCategory(result.result.category)
                        setScore(result.result.score)
                    }else{
                        alert(`Oops Sorry, any trouble... please Reload :) `);
                    }

                    setStatus("Done")
                    setSpamClick(false)
                })
                .catch((error) => console.error(error));
        }
    }

    return(
        <>
            <Topbar/>

            <section className="capability">

                <div className="breadcrumbDemo">
                    <Link to={"/"}>Home</Link>
                    <img src={arrow} alt="arrow"></img>
                    <Link to={"/demo"}>Demo</Link>
                    <img src={arrow} alt="arrow"></img>
                    <p style={{color: '#37C871'}}>Custom Image Classification</p>
                </div>

                <div className="contentCapability" id="errorClassification">
                    <p className="titleCapability">See How Custom Image Classification Transforms Businesses</p>
                    <div className="kotakCapability"></div>
                    <p className="textCapability">Experience the power of our Custom Image Classification technology through practical, real-world applications. Our demo showcases how this advanced feature can revolutionize various aspects of your business operations.</p>
                </div>

                <div className="mainCapability">

                    <div className="inputCapability">
                        <div className={errorClassification ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"} >                    
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{borderTopLeftRadius: '8px'}}>
                                            <input type="checkbox" onChange={(event) => 
                                            event.target.checked === true ?
                                            document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                                                checkbox.checked = true;
                                                setArrayDelete(
                                                    Array.from({ length: classification.length + 0 }, (_, i) => i)
                                                )
                                            })
                                            :
                                            document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                                                checkbox.checked = false;
                                                setArrayDelete([])
                                            }) }/>
                                        </th>
                                        <th>No</th>
                                        <th>Knowledge</th>
                                        <th style={{borderTopRightRadius: '8px'}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {classification.map((item, index) => (
                                        <>
                                        <tr>
                                            <td>
                                                <input id="box" type="checkbox" onChange={(event) => {
                                                    event.target.checked === true ? 
                                                    arrayDelete.push(index) : 
                                                    setArrayDelete(
                                                        arrayDelete.filter(animal => animal !== index)
                                                    );
                                                }}/>
                                            </td>
                                            <td>{index+1}</td>
                                            <td>{item}</td>
                                            <td>
                                                <button className="buttonGreen" onClick={() => {setOpen(!open); setValueText(item); setIndexText(index); setCondition("Edit")}}>
                                                    <img src={edit} alt="up"></img>
                                                    <p>Edit</p>
                                                </button>
                                            </td>
                                        </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>

                            <div className="menuInputCapability">
                                <button className="buttonSubmit" onClick={() => {
                                    // console.log(arrayDelete)
                                    arrayDelete.sort(); 
                                    setClassification(classification.filter((_, index) => !arrayDelete.includes(index)));
                                    document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                                        checkbox.checked = false;
                                    });
                                    setArrayDelete([])
                                }
                                }>Delete Data</button>
                                <button className="buttonSubmit" onClick={() => {setOpen(!open); setValueText(""); setIndexText(""); setCondition("Add")}} id="errorTarget">Input Knowledge</button>
                            </div>

                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorClassification ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your Classification :))
                            </p>
                        </div>
                        <div className="textAreaCapability">   
                            <label>Copy Example</label>
                            <p className="textExampleCapability" onClick={() => {setClassification(["Apple","Orange","Melon"])}}>Apple, Orange, Melon</p>
                        </div>
                    </div>

                    <div className="inputCapability">
                        <div className={errorTarget ? "textAreaCapability shadowCapability shadowErrorCapability" : "textAreaCapability shadowCapability"}>
                            <label>Media Upload</label>
                            
                            <Dropzone onDrop= {acceptedFiles => {
                                setFileTarget( acceptedFiles.map(file => Object.assign( file, {preview: URL.createObjectURL(file)})) )
                            }} accept={{'image/jpeg': [], 'image/png': []}} maxSize={1000000}>
                                {({getRootProps, getInputProps}) => (
                                    <section onClick={() => {setFileTarget([]); setExampleTarget(false)}}>
                                        <div className="uploadCapability" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={upload} alt="up"/>
                                            <p>Drag your file(s) to start uploading</p>
                                            <div className="orUpload">
                                                <div className="garisPutih"></div>
                                                <p>OR</p>
                                                <div className="garisPutih"></div>
                                            </div>
                                            <button className="buttonGreenFill">Browser file</button>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <div className="upCapability">
                                <p className="informationUploadCapability">Supported formats: JPEG, JPG, PNG</p>
                                <p className="informationUploadCapability">Maximum size: 1MB</p>
                            </div>


                            {fileTarget.map((item, index) => (
                                <div key={index} className="itemUploadCapability">
                                    <div className="itemInfoCapability">
                                        <div className="itemDetailInfoCapability">
                                            <img src={item.preview} width={120} alt="up"></img>
                                            <div className="itemKeteranganCapability">
                                                <p className="itemNameCapability">{item.name}</p>
                                                <p className="itemSizeCapability">{formatBytes(item.size)}</p>
                                            </div>
                                        </div>
                                        <img src={x} onClick={() => {setFileTarget([]); setExampleTarget(false)}} style={{cursor: 'pointer'}} alt="up"/>
                                    </div>
                                </div>
                            ))}


                            <p className="noMargin"
                                style={{
                                color: '#bd2f2f',
                                opacity: errorTarget ? "1" : "0", 
                                transition: "all .5s", 
                                }}>
                                    Please input your image Target :))
                            </p>

                        </div>
                        <div className="textAreaCapability">
                            <label>Copy Example</label>
                            <img src={apple} width={120} alt="up"/>
                            <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => {setFileTarget([{preview: apple, name: 'apple.jpg', size: 0}]); setExampleTarget(true)}}>Copy Example</button>
                        </div>
                    </div>

                    <div className="submitCapability">
                        <button className="buttonSubmit" style={{width: 'max-content'}} onClick={() => submitImage()} disabled={spamClick}>Submit</button>
                        {spamClick === true && (
                            <img src={loading} width={60} alt="up"></img>
                        )}
                    </div>

                    <div>
                        <div className="textAreaCapability">
                            <label>Result</label>
                            <div className="resultCapability" style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                {status === "Done" ? (
                                    <>
                                    {category.map((item, index) => (
                                        <div key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '12px'}}>
                                            <p style={{fontSize: '16px', wordBreak: 'initial'}}>{item}</p>
                                            <progress value={score[index]} max="100"></progress>
                                            <p style={{wordBreak: 'auto-phrase', fontSize: '16px' }}>{(score[index]).toFixed(1)}%</p>
                                        </div>
                                    ))}
                                    </>
                                ) : (
                                    <>
                                    {status === "Loading" ? (
                                        <img src={loading} style={{width: '100%'}} alt="up"></img>
                                    ) : (
                                        <p>{status}</p>
                                    )}
                                    </>
                                )}



                            </div>
                        </div>
                    </div>
                </div>



            </section>

            <Footer/>

            <Modal isOpen={open} onRequestClose={() => setOpen(!open)} className="ModalCapability" overlayClassName="OverlayFooter" ariaHideApp={false} contentLabel="Selected Option">

                {/*BENERIN CSS */}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p className="modalCapabilityAddContext">Add Context</p>

                    <label className="modalCapabilityLabel">Input Knowledge</label>
                    <input className="modalCapabilityInput" type="text" placeholder="Apple" value={valueText} onChange={(event) => {setValueText(event.target.value)}}/>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button className="buttonGrayBorder" onClick={() => {setOpen(!open)}}>Cancel</button>
                        <button className="buttonGreenDark" onClick={() => { 
                            valueText !== "" ? 
                                condition === "Add" ? 
                                    classification.push(valueText) : 
                                    classification[indexText] = valueText
                            : console.log("ISI DONG");

                            setOpen(!open);

                        }}>{condition}</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CustomImage