import '../../../assets/style/HPC1.css';

import bg from "../../../assets/photo/Background.webp";
import fr1 from "../../../assets/photo/frame1.webp";
import fr2 from "../../../assets/photo/frame2.webp";
import fr3 from "../../../assets/photo/frame3.webp";
import fr4 from "../../../assets/photo/frame4.webp";

function HPC1() {
    
    const listPicture = [{
        src: fr1,
        no: 1
    },
    {
        src: fr2,
        no: 2
    },
    {
        src: fr3,
        no: 3
    },
    {
        src: fr4,
        no: 4
    }];

    return(
        <section className='HPC1'>
            <div className='mainHPC1'>
                <img src={bg} className='goneMobile width100' alt="background"/>
                <div className='contentMainHPC1 goneMobile'>
                    <h1 className='white'>The Edge of Human <br/>Knowledge is Here</h1>
                    <p className='white' style={{marginBottom: '6%'}}>We Held Implement Technologies and Get The Real Measurable Result</p>
                    <button className='buttonGrey' onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>Let's Collaborate!</button>
                    <div className='previewHPC1'>
                        {listPicture.map((item, index) => (
                            <div key={index} style={{flex: '1'}}>
                                <img src={item.src} className={index === 0 ? 'leftBorder width100' : index === 3 ? 'rightBorder width100' : 'width100'} alt="listdemo"/>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Mobile */}
                <div className='contentMainHPC1Mobile'>
                    <h1 className='white'>The Edge of Human <br/>Knowledge is Here</h1>
                    <p className='white' style={{marginBottom: '6%'}}>We Held Implement Technologies and Get The Real Measurable Result</p>
                    <button className='buttonGrey' onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>Let's Collaborate!</button>
                </div>

            </div>
        </section>
    )
}

export default HPC1;