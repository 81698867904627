import Topbar from "../../component/Topbar/Topbar";
import Footer from "../../component/Footer/Footer";
import checklist from "../../assets/icon/checklist.svg";
import checklistSize from "../../assets/icon/checklistSize.svg";

import bg from "../../assets/photo/bigDataBackground.webp"

import "../../assets/style/BigData.css"


function BigData(params) {

    const listitem = [
        "Data Collection",
        "Data Storage",
        "E-commerce Integration",
        "Content Management Systems (CMS)",
        "Machine Learning Integration",
        "Security & Compliance"
    ]

    const listwhy = [{
        title: "Expertise",
        text: "Our team of data scientists and engineers brings extensive experience and cutting-edge skills.",
    },
    {
        title: "Customization",
        text: "Tailored solutions that align with your business needs and goals.",
    },
    {
        title: "Innovation",
        text: "Utilize the latest technologies to stay competitive in the market.",
    },
    {
        title: "Scalability ",
        text: "Solutions designed to grow with your business, handling increasing volumes of data seamlessly.",
    },
    {
        title: "Support",
        text: "Ongoing assistance and support to ensure your data operations run smoothly.",
    },
    ]

    return(
        <>
            <Topbar/>
        
            <section className='big'>
                <div className='mainBig'>
                    <img src={bg} className='backgroundMainBig' alt="background"/>
                    <div className='contentMainBig'>
                        <h1 className='white'>Big Data Intelligence</h1>
                    </div>

                    {/* <div className='contentMainBigMobile'>
                        <h1 className='white'>Big Data Intelligence</h1>
                    </div> */}
                </div>
            </section>

            <section className="contentBig">
                <div className="width70">
                    <h1>Harness the Power of Data</h1>
                    <p className="textNormal mb40">In today's fast-paced world, big data is crucial for companies looking to stay ahead. Managing and making sense of this data requires advanced intelligence and expertise. That's where we come in.</p>
                
                    <p className="titleNormal">Big Data Intelligence Solutions</p>
                    <p className="textNormal">Transform your data into actionable insights with our comprehensive big data intelligence services. We offer:</p>

                    {listitem.map((item, index) => (
                    <div key={index} className="itemBig">
                        <div className="dflex" alt="check">
                            <img src={checklist} alt="what?"/>
                        </div>
                        <p className="textNormal textAlignStart">{item}</p>
                    </div>
                    ))}

                    <p className="titleNormal">Why Choose Our Big Data Intelligence Services?</p>

                    {listwhy.map((item, index) => (
                    <div key={index} className="itemWhy">
                        <div className="dflex">
                            <img src={checklistSize} alt="check"/>
                        </div>
                        <div>
                        <h2 className="titleItemWhyBig">{item.title}</h2>
                        <h2 className="textItemWhyBig">{item.text}</h2>
                        </div>
                    </div>
                    ))}
                </div>

                <div className="cardSoftware">
                    <p className="titleHigh">Ready to Transform Your Business?</p>
                    <p className="textNormal mb64">Take the first step towards innovation and growth. Schedule your free consultation today and see how INFIDEA can turn your ideas into reality.</p>
                    <div>
                        <button className="buttonBlack" onClick={() => {window.open('https://wa.me/6281703090603', '_blank')}}>Let's Collaborate!</button>
                    </div>
                    
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default BigData