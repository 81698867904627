// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HPC1{
    margin: 168px 4% 0% 4%;
}
.mainHPC1{
    display: flex;
    justify-content: center;
    width: 100%;
}
.previewHPC1{
    display: flex;
    width: 85%;
    margin-top: 5%;
    border-radius: 24px;
}
.contentMainHPC1{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    margin-top: 6%;
    text-align: center;
}

.contentMainHPC1Mobile{
    display: none;
}

@media screen and (max-width: 1280px) {
    .contentMainHPC1Mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4%;
    }
    .contentMainHPC1Mobile h1{
        font-size: 32px;
        color: #2f2f2f ;
        text-align: center;
    }
    .contentMainHPC1Mobile p{
        font-size: 18px;
        color: #808080;
        text-align: center;
    }
    .contentMainHPC1Mobile button{
        font-size: 14px;
        padding: 12px 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/HPC1.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,aAAa;IACb,UAAU;IACV,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,cAAc;IAClB;IACA;QACI,eAAe;QACf,eAAe;QACf,kBAAkB;IACtB;IACA;QACI,eAAe;QACf,cAAc;QACd,kBAAkB;IACtB;IACA;QACI,eAAe;QACf,kBAAkB;IACtB;AACJ","sourcesContent":[".HPC1{\n    margin: 168px 4% 0% 4%;\n}\n.mainHPC1{\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}\n.previewHPC1{\n    display: flex;\n    width: 85%;\n    margin-top: 5%;\n    border-radius: 24px;\n}\n.contentMainHPC1{\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 92%;\n    margin-top: 6%;\n    text-align: center;\n}\n\n.contentMainHPC1Mobile{\n    display: none;\n}\n\n@media screen and (max-width: 1280px) {\n    .contentMainHPC1Mobile{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        margin-top: 4%;\n    }\n    .contentMainHPC1Mobile h1{\n        font-size: 32px;\n        color: #2f2f2f ;\n        text-align: center;\n    }\n    .contentMainHPC1Mobile p{\n        font-size: 18px;\n        color: #808080;\n        text-align: center;\n    }\n    .contentMainHPC1Mobile button{\n        font-size: 14px;\n        padding: 12px 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
